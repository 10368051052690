import React from 'react';

import { Box, Input } from '@otovo/rainbow';

export const source = `
<Box p="5" bg="lilac_90">
  <Input contrastTo="darkBackground" />
</Box>

<Box p="5" bg="lilac_10">
  <Input contrastTo="lightBackground" />
</Box>
`;

export const render = (
  <>
    <Box p="5" bg="darkLilac">
      <Input contrastTo="darkBackground" />
    </Box>
    <Box p="5" bg="lilac_5">
      <Input contrastTo="lightBackground" />
    </Box>
  </>
);
