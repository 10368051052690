import React from 'react';
import { Button, H5 } from '@otovo/rainbow';

import IconArrowRight from './IconArrowRight';
import IconGlobe from './IconGlobe';
import IconLayers from './IconLayers';

export const source = `
<H5>No icon</H5>
<Button>Next</Button>
<Button loading>Next</Button>

<H5>Icon + space-between layout</H5>
<Button icon={<IconArrowRight />} layout="space-between">
  Next
</Button>
<Button icon={<IconArrowRight />} layout="space-between" loading>
  Next
</Button>

<H5>Icon + center layout</H5>
<Button icon={<IconLayers />} layout="center">
  More
</Button>
<Button icon={<IconLayers />} layout="center" loading>
  More
</Button>

<H5>Icon + left layout</H5>
<Button icon={<IconGlobe />} layout="left">
  Browse
</Button>
<Button icon={<IconGlobe />} layout="left" loading>
  Browse
</Button>
`;

export const render = (
  <>
    <H5 mb="2">No icon</H5>
    <Button display="flex" mb="2">
      Next
    </Button>
    <Button display="flex" mb="6" loading>
      Next
    </Button>

    <H5 mb="2">Icon + space-between layout</H5>
    <Button
      display="flex"
      mb="2"
      icon={<IconArrowRight />}
      layout="space-between">
      Next
    </Button>
    <Button
      display="flex"
      mb="6"
      icon={<IconArrowRight />}
      layout="space-between"
      loading>
      Next
    </Button>

    <H5 mb="2">Icon + center layout</H5>
    <Button display="flex" mb="2" icon={<IconLayers />} layout="center">
      More
    </Button>
    <Button display="flex" mb="6" icon={<IconLayers />} layout="center" loading>
      More
    </Button>

    <H5 mb="2">Icon + left layout</H5>
    <Button display="flex" mb="2" icon={<IconGlobe />} layout="left">
      Browse
    </Button>
    <Button display="flex" mb="6" icon={<IconGlobe />} layout="left" loading>
      Browse
    </Button>
  </>
);
