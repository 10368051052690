import styled from '@emotion/styled';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import { get } from '@otovo/rainbow';

// For more, see https://github.com/atomiks/tippy.js-react
const Tooltip = styled(Tippy)`
  font-family: ${get('fonts.sansSerif')};
  background-color: ${get('colors.grey_100')};

  &[data-placement^='top'] .tippy-arrow,
  &[data-placement^='lef'] .tippy-arrow,
  &[data-placement^='bottom'] .tippy-arrow,
  &[data-placement^='right'] .tippy-arrow {
    border-top-color: ${get('colors.grey_100')};
  }
`;

export default Tooltip;
