import React from 'react';
import { H1, BodyM, CaptionXS, Link } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';

import * as exampleBasic from './examples/exampleBasic';
import * as exampleInteractive from './examples/exampleInteractive';
import * as exampleDisabled from './examples/exampleDisabled';
import * as exampleContrastTo from './examples/exampleContrastTo';
import * as exampleSize from './examples/exampleSize';

const TogglePage = () => (
  <Box>
    <CaptionXS as="h1">Toggle</CaptionXS>
    <H1 as="h2" mb="3">
      Required props
    </H1>
    <BodyM mb="6" maxWidth="measure">
      The only required prop for <Code>Toggle</Code> is <Code>id</Code>. All
      other props are optional.
    </BodyM>

    <Example {...exampleBasic} />

    <HR />

    <CaptionXS>Toggle</CaptionXS>
    <H1 as="h2" mb="3">
      Making it interactive
    </H1>
    <BodyM mb="6" maxWidth="measure">
      <Code>Toggle</Code> works the same way as regular{' '}
      <Link href="https://reactjs.org/docs/forms.html#controlled-components">
        controlled components
      </Link>
      . Make it interactive by adding the <Code>checked</Code> and{' '}
      <Code>onChange</Code> props. If these props are omitted, Toggle will
      behave as an uncontrolled component.
    </BodyM>

    <Example {...exampleInteractive} />

    <HR />

    <CaptionXS>Toggle</CaptionXS>
    <H1 as="h2" mb="3">
      Size
    </H1>
    <BodyM mb="6" maxWidth="measure">
      You can change the size using the <Code>size</Code> prop. It accepts
      pixels or a <Link href="/atoms/sizes">size scale number</Link>.
    </BodyM>
    <Example {...exampleSize} />

    <HR />

    <CaptionXS>Toggle</CaptionXS>
    <H1 as="h2" mb="3">
      ContrastTo
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>contrastTo</Code> prop to ensure good contrast against the
      background. Two values are accepted: <Code>lightBackground</Code>{' '}
      (default) and <Code>darkBackground</Code>.
    </BodyM>
    <Example {...exampleContrastTo} />

    <HR />

    <CaptionXS>Toggle</CaptionXS>
    <H1 as="h2" mb="3">
      Disabled
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Disable the component by adding the <Code>disabled</Code> prop.
    </BodyM>
    <Example {...exampleDisabled} />
  </Box>
);

export default TogglePage;
