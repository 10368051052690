import React from 'react';

import { GradientText, H1 } from '@otovo/rainbow';

export const source = `
<H1 mb="5">
  <GradientText>Default</GradientText>
</H1>

<H1 mb="5">
  <GradientText backgroundImage="gradientBlue">Built-in</GradientText>
</H1>

<H1>
  <GradientText backgroundImage="linear-gradient(45deg, hotpink, #c709b0)">
    Custom
  </GradientText>
</H1>
`;

export const render = (
  <>
    <H1 as="p" mb="7">
      <GradientText>Default</GradientText>
    </H1>
    <H1 as="p" mb="7">
      <GradientText backgroundImage="gradientBlue">Built-in</GradientText>
    </H1>
    <H1 as="p">
      <GradientText backgroundImage="linear-gradient(45deg, hotpink, #c709b0)">
        Custom
      </GradientText>
    </H1>
  </>
);
