import React from 'react';
import { H1, H3, BodyM, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';
import Ol from '../../components/Ol';

import * as examplePrimary from './examples/examplePrimary';
import * as exampleSecondary from './examples/exampleSecondary';
import * as exampleTertiary from './examples/exampleTertiary';
import * as exampleWidths from './examples/exampleWidths';
import * as exampleLinks from './examples/exampleLinks';
import * as exampleDisabled from './examples/exampleDisabled';
import * as examplePairing1 from './examples/examplePairing1';
import * as examplePairing2 from './examples/examplePairing2';
import * as exampleLoading from './examples/exampleLoading';
import * as exampleLoading2 from './examples/exampleLoading2';
import * as exampleLoading3 from './examples/exampleLoading3';
import * as exampleIcon from './examples/exampleIcon';
import * as exampleMaxWidth from './examples/exampleMaxWidth';
import * as exampleLayout from './examples/exampleLayout';
import * as exampleLayout2 from './examples/exampleLayout2';
import * as exampleShape from './examples/exampleShape';
import * as exampleShape2 from './examples/exampleShape2';

const ButtonsPage = () => (
  <Box>
    <CaptionXS as="h1">Button</CaptionXS>
    <H1 as="h2" mb="3">
      Variants
    </H1>
    <BodyM mb="6" maxWidth="measure">
      There are a total of four different variants: Two primary buttons (blue
      and peach), a secondary button, and a tertiary button. Try to avoid having
      more than one primary button on a given page.
    </BodyM>

    <H3 mb="2">Primary</H3>
    <Example {...examplePrimary} />

    <H3 mb="2">Secondary</H3>
    <Example {...exampleSecondary} />

    <H3 mb="2">Tertiary</H3>
    <Example {...exampleTertiary} />

    <HR />
    <CaptionXS>Button</CaptionXS>
    <H1 as="h2" mb="3">
      Widths
    </H1>
    <BodyM mb="6" maxWidth="measure">
      The button defaults to minimum 160px width and grows based on its content.
      Override by using the <Code>width</Code> prop, for example{' '}
      <Code>width="100%"</Code>.
    </BodyM>
    <Example {...exampleWidths} />

    <H3 mb="2">Controlling the buttons max-width</H3>
    <BodyM mb="6" maxWidth="measure">
      Add <Code>maxWidth="..."</Code> to the <strong>button's parent</strong>,
      and <Code>width="100%"</Code> to the <strong>button itself</strong>.
      Setting maxWidth on the button directly unfortunately doesn't work.
    </BodyM>
    <Example {...exampleMaxWidth} />

    <HR />
    <CaptionXS>Button</CaptionXS>
    <H1 as="h2" mb="3">
      Links
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Style links as buttons by adding the props <Code>as="a"</Code> and{' '}
      <Code>href="magical-destination.biz"</Code>
    </BodyM>
    <Example {...exampleLinks} />

    <HR />
    <CaptionXS>Button</CaptionXS>
    <H1 as="h2" mb="3">
      Disabled
    </H1>
    <BodyM mb="3" maxWidth="measure">
      Disable buttons by adding the <Code>disabled</Code> prop.
    </BodyM>
    <BodyM mb="6" maxWidth="measure">
      <strong>NB:</strong> If used on links styled as buttons, the button will
      still work if clicked. To avoid accidental clicks, you'll need to prohibit
      this manually (for example by removing the <Code>href</Code> prop).
    </BodyM>
    <Example {...exampleDisabled} />

    <HR />
    <CaptionXS>Button</CaptionXS>
    <H1 as="h2" mb="3">
      Loading
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>loading</Code> prop to add a spinner.
    </BodyM>
    <Example {...exampleLoading} />

    <H3 mb="2">Interactive example</H3>
    <BodyM mb="6" maxWidth="measure">
      Note how the button width doesn't change when showing the spinner. This is
      done to prevent the janky layout shifts.
    </BodyM>

    <Example {...exampleLoading2} />

    <H3 mb="2">Disabled while loading</H3>
    <BodyM mb="6" maxWidth="measure">
      By default, <strong>onClick</strong> won't be triggered when{' '}
      <strong>loading</strong> is true. However, you can override this behaviour
      by explicitcly setting <Code>disabled={'{false}'}</Code>.
    </BodyM>
    <Example {...exampleLoading3} />

    <HR />
    <CaptionXS>Button</CaptionXS>
    <H1 as="h2" mb="3">
      Icons
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Icons can be added using the <Code>icon</Code> prop. To work properly, a
      few requirements must be met:
    </BodyM>
    <Ol mb="6">
      <li>
        The icon must be a <strong>"reactified" SVG</strong>
      </li>
      <li>
        The icon's <strong>width</strong> and <strong>height</strong> must be
        set to <Code>1em</Code>
      </li>
      <li>
        The icon's <strong>color</strong> must be set to{' '}
        <Code>currentColor</Code>
      </li>
    </Ol>
    <Example {...exampleIcon} />

    <HR />
    <CaptionXS>Button</CaptionXS>
    <H1 as="h2" mb="3">
      Shape
    </H1>

    <BodyM mb="6" maxWidth="measure">
      Two different <strong>shapes</strong> are supported: <br />
      <Code>pill</Code> (default) and
      <Code>circle</Code>.
    </BodyM>
    <Example {...exampleShape} />

    <H3 mb="2">Circle</H3>
    <BodyM mb="6" maxWidth="measure">
      The circle shape supports props such as <Code>loading</Code>,{' '}
      <Code>disabled</Code> and <Code>variant</Code>
    </BodyM>

    <BodyM mb="6" maxWidth="measure">
      <strong>Accessibility tip!</strong> When using the <strong>circle</strong>{' '}
      shape, make sure to add some text. Even though it is visually hidden,
      screen readers will still make use of this text.
    </BodyM>
    <Example {...exampleShape2} />

    <HR />
    <CaptionXS>Button</CaptionXS>
    <H1 as="h2" mb="3">
      Layout
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>layout</Code> prop to change the position of the text and
      icon within the button.
    </BodyM>
    <Example {...exampleLayout} />

    <H3 mb="2">Default layout</H3>
    <BodyM mb="6" maxWidth="measure">
      The default layout is <strong>center</strong>. When an <Code>icon</Code>{' '}
      is provided, the default layout is <strong>space-between</strong>.
    </BodyM>

    <H3 mb="2">Layout and loading</H3>
    <BodyM mb="6" maxWidth="measure">
      If the button is <Code>loading</Code>, the spinner will replace the text.
      If an <Code>icon</Code> is present, the icon will be be replaced instead
      of the text.
    </BodyM>
    <Example {...exampleLayout2} />

    <HR />
    <H1 as="h2" mb="3">
      Best practices
    </H1>
    <H3 mb="2">Placement</H3>
    <BodyM mb="6" maxWidth="measure">
      We read from left to right, top to bottom, and finish a page on the bottom
      right. Buttons in a process should be placed on the bottom right corner.
      Otherwise, align the buttons with the same alignment as the preceeding
      paragraph.
    </BodyM>

    <H3 mb="2">Pairings</H3>
    <BodyM mb="6" maxWidth="measure">
      Avoid pairing multiple primary buttons. Instead, pair them with secondary
      or tertiary buttons.
    </BodyM>
    <Example {...examplePairing1} />
    <Example {...examplePairing2} />
  </Box>
);

export default ButtonsPage;
