import React from 'react';
import styled from '@emotion/styled';

import { get, H1, H3, BodyM, CaptionXS, Text } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';

import TextDefinition from './TextDefinition';

const ExampleText = styled(Text)`
  word-break: break-all;
  color: ${get('colors.text_heading')};
`;

const TypographyPage = () => {
  return (
    <>
      <CaptionXS as="h1">Typography</CaptionXS>
      <H1 as="h2" mb="2">
        Font
      </H1>
      <BodyM color="grey_60" mb="7">
        Poppins
      </BodyM>

      <Box display="flex" flexWrap="wrap">
        <Box width={{ s: '100%', m: 1 / 3 }} pr="6" mb={{ s: '6', m: '7' }}>
          <ExampleText fontWeight="600" mb="2">
            Poppins Semibold (600)
          </ExampleText>
          <ExampleText fontWeight="600">
            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890!”#$%&@([?!])
          </ExampleText>
        </Box>
        <Box width={{ s: '100%', m: 1 / 3 }} pr="6" mb={{ s: '6', m: '7' }}>
          <ExampleText fontWeight="500" mb="2">
            Poppins Medium (500)
          </ExampleText>
          <ExampleText fontWeight="500">
            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890!”#$%&@([?!])
          </ExampleText>
        </Box>
        <Box width={{ s: '100%', m: 1 / 3 }} pr="6" mb={{ s: '6', m: '7' }}>
          <ExampleText fontWeight="400" mb="2">
            Poppins Regular (400)
          </ExampleText>
          <ExampleText fontWeight="400">
            AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890!”#$%&@([?!])
          </ExampleText>
        </Box>
      </Box>

      <HR />

      <H1 as="h2" mb="7">
        Text styles
      </H1>

      <TextDefinition
        tag="H0"
        size="36px, 44px, 46px"
        weight="Semibold"
        tracking="0.0125em"
        lineHeight="1.25">
        We make solar simple and affordable
      </TextDefinition>
      <TextDefinition
        tag="H1"
        size="30px, 34px, 36px"
        weight="Semibold"
        tracking="0.0125em"
        lineHeight="1.25">
        We make solar simple and affordable
      </TextDefinition>
      <TextDefinition
        tag="H2"
        size="26px, 28px, 30px"
        weight="Semibold"
        tracking="0.0125em"
        lineHeight="1.375">
        We make solar simple and affordable
      </TextDefinition>
      <TextDefinition
        tag="H3"
        size="22px, 24px, 24px"
        weight="Semibold"
        tracking="0.0125em"
        lineHeight="1.375">
        We make solar simple and affordable
      </TextDefinition>
      <TextDefinition
        tag="H4"
        size="20px"
        weight="Semibold"
        tracking="0.0125em"
        lineHeight="1.5">
        We make solar simple and affordable
      </TextDefinition>
      <TextDefinition
        tag="H5"
        size="18px"
        weight="Semibold"
        tracking="0.0125em"
        lineHeight="1.5">
        We make solar simple and affordable
      </TextDefinition>
      <TextDefinition
        tag="H6"
        size="16px"
        weight="Semibold"
        tracking="0.0125em"
        lineHeight="1.5">
        We make solar simple and affordable
      </TextDefinition>

      <TextDefinition
        tag="Body L"
        size="18px"
        weight="Regular"
        tracking="0.0125em"
        lineHeight="1.5">
        With solar panels, it is easy to be both economical and environmental.
        This video explains how solar panels are a viable investment.
      </TextDefinition>
      <TextDefinition
        tag="Body M"
        size="16px"
        weight="Regular"
        tracking="0.0125em"
        lineHeight="1.5">
        With solar panels, it is easy to be both economical and environmental.
        This video explains how solar panels are a viable investment.
      </TextDefinition>
      <TextDefinition
        tag="Body S"
        size="14px"
        weight="Regular"
        tracking="0.0125em"
        lineHeight="1.375">
        With solar panels, it is easy to be both economical and environmental.
        This video explains how solar panels are a viable investment.
      </TextDefinition>
      <TextDefinition
        tag="Body XS"
        size="12px"
        weight="Regular"
        tracking="0.0125em"
        lineHeight="1.375">
        With solar panels, it is easy to be both economical and environmental.
        This video explains how solar panels are a viable investment.
      </TextDefinition>

      <TextDefinition
        tag="Caption M"
        size="16px"
        weight="Regular"
        tracking="0.05em"
        lineHeight="1.25">
        Dream of light
      </TextDefinition>
      <TextDefinition
        tag="Caption S"
        size="14px"
        weight="Regular"
        tracking="0.05em"
        lineHeight="1.25">
        Dream of light
      </TextDefinition>
      <TextDefinition
        tag="Caption XS"
        size="12px"
        weight="Regular"
        tracking="0.05em"
        lineHeight="1.25">
        Dream of light
      </TextDefinition>

      <HR />

      <H1 as="h2" mb="2">
        Guidelines
      </H1>
      <BodyM color="grey_60" mb="7">
        Font usage
      </BodyM>

      <H3 mb="2">Font hierarchy</H3>
      <Box maxWidth="measure" mb="4">
        <BodyM>
          Text should always have a clear visual hierarchy to help the users
          orientate themselves on the page and to make it easy to quickly scan
          the page for the information one are seeking at a given time. The
          hierarchy is created by font size, weight and color.
        </BodyM>
      </Box>
      <Box maxWidth="measure">
        <BodyM>
          Always start every page and chapter asking yourself “What is it the
          user is looking for on this page or chapter?” and “What is the most
          important message to get through to the user?”
        </BodyM>
      </Box>
    </>
  );
};

export default TypographyPage;
