import React from 'react';

import {
  Form,
  Button,
  Input,
  Label,
  LabelDescription,
  ErrorMessage,
} from '@otovo/rainbow';

export const source = `
<Form
  contrastTo="lightBackground"
  bg="lilac_5"
  p="5"
  onSubmit={…}>
  // Form content
</Form>
`;

export const render = (
  <Form
    contrastTo="lightBackground"
    bg="lilac_5"
    p="5"
    onSubmit={() => {
      alert('I feel sooo submitted right now');
    }}>
    <Label htmlFor="name">Name</Label>
    <LabelDescription mb="2" id="name-description">
      Your full name
    </LabelDescription>
    <Input id="name" aria-describedby="name-description" />

    <Label htmlFor="occupation" mt="5">
      Occupation
    </Label>
    <LabelDescription mb="2" id="occupation-description">
      As in "job", not as in "using military force to take territory"
    </LabelDescription>
    <Input
      id="occupation"
      aria-describedby="occupation-error occupation-description"
      validationState="error"
    />
    <ErrorMessage id="occupation-error" mt="2">
      Uh oh! Someones in trouble!
    </ErrorMessage>

    <Label htmlFor="framework" mb="1" mt="5">
      Favorite framework
    </Label>
    <Input id="framework" value="Rainbow" disabled />

    <Button variant="blue" type="submit" mt="5">
      Submit
    </Button>
  </Form>
);
