import React from 'react';

import { InputField, Box } from '@otovo/rainbow';

export const source = `
<Box bg="darkLilac" p="5">
  <InputField
    contrastTo="darkBackground"
    id="contrastToExample"
    label="Password"
    labelDescription="Must contain at least 6 characters"
    errorMessage="This field is required"
    validationState="error"
  />
</Box>
<Box bg="lilac_5" p="5">
  <InputField
    id="contrastToExample2"
    label="Password"
    labelDescription="Must contain at least 6 characters"
    errorMessage="This field is required"
    validationState="error"
  />
</Box>
`;

export const render = (
  <>
    <Box bg="darkLilac" p="5">
      <InputField
        contrastTo="darkBackground"
        id="contrastToExample"
        label="Password"
        labelDescription="Must contain at least 6 characters"
        errorMessage="This field is required"
        validationState="error"
      />
    </Box>
    <Box bg="lilac_5" p="5">
      <InputField
        id="contrastToExample2"
        label="Password"
        labelDescription="Must contain at least 6 characters"
        errorMessage="This field is required"
        validationState="error"
      />
    </Box>
  </>
);
