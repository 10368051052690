import React from 'react';
import { withTheme } from 'emotion-theming';
import { Box } from '@otovo/rainbow';

type Props = {
  size?: string,
  bg?: string,
  color?: string,
  theme: Object,
};

const Checkmark = ({
  size = '5',
  bg = 'royalBlue',
  color = 'white',
  theme,
  ...rest
}: Props = {}) => {
  const { colors, sizes } = theme;
  return (
    <Box
      width={size}
      height={size}
      fontSize={sizes[size] || size}
      color={color}
      {...rest}>
      <svg
        height="1em"
        width="1em"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          display: 'block',
        }}>
        <g fill="none" fillRule="evenodd">
          <circle fill={colors[bg] || bg} cx="12" cy="12" r="12" />
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="m10.1428571 13.1286797 5.4393399-5.43933987c.5857864-.58578644 1.5355339-.58578644 2.1213203 0 .5857865.58578644.5857865 1.5355339 0 2.12132034l-6.5 6.50000003c-.5857864.5857864-1.53553389.5857864-2.12132033 0l-2.78571428-2.7857143c-.58578644-.5857865-.58578644-1.5355339 0-2.1213204.58578643-.5857864 1.5355339-.5857864 2.12132034 0z"
          />
        </g>
      </svg>
    </Box>
  );
};

export default withTheme(Checkmark);
