import React from 'react';
import { Button, Box } from '@otovo/rainbow';

import ArrowRight from './IconArrowRight';

export const source = `
import IconArrowRight from 'some/folder';

<Button variant="blue" icon={<ArrowRight />}>
  Continue
</Button>
<Button variant="blue" loading icon={<ArrowRight />}>
  Continue
</Button>
<Button variant="blue" disabled icon={<ArrowRight />}>
  Continue
</Button>

// Peach, secondary, and tertiary variants hidden for brevity
`;

export const render = (
  <>
    <Box display="flex" mb="2">
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="blue"
        display="flex"
        mr="2">
        Continue
      </Button>
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="blue"
        display="flex"
        mr="2"
        loading>
        Continue
      </Button>
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="blue"
        display="flex"
        mr="2"
        disabled>
        Continue
      </Button>
    </Box>

    <Box display="flex" mb="2">
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="peach"
        display="flex"
        mr="2">
        Continue
      </Button>
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="peach"
        display="flex"
        mr="2"
        loading>
        Continue
      </Button>
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="peach"
        display="flex"
        mr="2"
        disabled>
        Continue
      </Button>
    </Box>

    <Box display="flex" mb="2">
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="secondary"
        display="flex"
        mr="2">
        Continue
      </Button>
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="secondary"
        display="flex"
        mr="2"
        loading>
        Continue
      </Button>
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="secondary"
        display="flex"
        mr="2"
        disabled>
        Continue
      </Button>
    </Box>

    <Box display="flex" mb="2">
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="tertiary"
        display="flex"
        mr="2">
        Continue
      </Button>
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="tertiary"
        display="flex"
        mr="2"
        loading>
        Continue
      </Button>
      <Button
        icon={<ArrowRight />}
        shape="circle"
        variant="tertiary"
        display="flex"
        mr="2"
        disabled>
        Continue
      </Button>
    </Box>
  </>
);
