// Source: https://css-tricks.com/converting-color-spaces-in-javascript/
export default function hexToRgb(h, isPct = false) {
  let r = 0,
    g = 0,
    b = 0;

  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  if (isPct) {
    r = +((r / 255) * 100).toFixed(1);
    g = +((g / 255) * 100).toFixed(1);
    b = +((b / 255) * 100).toFixed(1);
  }

  return (
    'rgb(' +
    (isPct ? r + '%,' + g + '%,' + b + '%' : +r + ',' + +g + ',' + +b) +
    ')'
  );
}
