import React from 'react';
import styled from '@emotion/styled';

import { get } from '@otovo/rainbow';
import Box from '../../components/Box';

import { HEX, HSL, RGB, CMYK } from './constants';

const ColorspaceButton = styled.button`
  border: none;
  background-color: ${p => (p.active ? get('colors.blue_5') : 'transparent')};
  color: ${get('colors.royalBlue')};
  border-radius: ${get('radii.pill')};
  padding: ${get('space.2')} ${get('space.5')};
  margin-right: ${get('space.1')};
  font-size: ${get('fontSizes.2')};
  cursor: pointer;
  outline: none;

  :hover {
    background-color: ${get('colors.blue_5')};
  }
`;

const ColorspaceButtons = ({ activeColorSpace, onClick }) => (
  <Box mb="3">
    <ColorspaceButton
      active={activeColorSpace === HEX}
      onClick={() => {
        onClick(HEX);
      }}>
      HEX
    </ColorspaceButton>
    <ColorspaceButton
      active={activeColorSpace === HSL}
      onClick={() => {
        onClick(HSL);
      }}>
      HSL
    </ColorspaceButton>
    <ColorspaceButton
      active={activeColorSpace === RGB}
      onClick={() => {
        onClick(RGB);
      }}>
      RGB
    </ColorspaceButton>
    <ColorspaceButton
      active={activeColorSpace === CMYK}
      onClick={() => {
        onClick(CMYK);
      }}>
      CMYK
    </ColorspaceButton>
  </Box>
);

export default ColorspaceButtons;
