import React from 'react';
import { H1, BodyM, CaptionXS, Button } from '@otovo/rainbow';

import Box from '../../components/Box';

const LandingPage = () => (
  <Box>
    <CaptionXS>Design System</CaptionXS>
    <H1 mb="6">Content Style Guide</H1>

    <BodyM mb="6" maxWidth="measure">
      The purpose of the content style guide is to speak with one voice towards
      our customers. The guide describes our <strong>voice and tone</strong> and{' '}
      <strong>writing goals</strong>, as well as some more specific topis like{' '}
      <strong>glossary</strong>, <strong>capitalization</strong> and{' '}
      <strong>emoji usage</strong>.
    </BodyM>

    <Button
      as="a"
      href="https://otovo.brandmaster.com/brandcenter/en/otovobh/"
      variant="blue">
      See the guide in Brandmaster
    </Button>
  </Box>
);

export default LandingPage;
