import React from 'react';
import styled from '@emotion/styled';
import { get, BodyM, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';

import hexToCmyk from './utils/hexToCmyk';
import hexToHsl from './utils/hexToHsl';
import hexToRgb from './utils/hexToRgb';
import stripColorSystemFromString from './utils/stripColorSystemFromString';

const Swatch = ({ color }) => {
  return (
    <Box
      gridArea="swatch"
      bg={color}
      position="relative"
      width="100%"
      minHeight="10"
      borderBottom="1"
      borderColor="lilac_10"
    />
  );
};

const CardWrap = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'swatch swatch'
    'name   name'
    'hex    hsl'
    'rgb    cmyk';
  background: white;
  box-shadow: ${get('shadows.0')};
`;

const ColorCard = ({ width, colorStyle, name }) => {
  const hex = colorStyle.toUpperCase();
  const hsl = stripColorSystemFromString(hexToHsl(colorStyle));
  const rgb = stripColorSystemFromString(hexToRgb(colorStyle));
  const cmyk = stripColorSystemFromString(hexToCmyk(colorStyle));

  return (
    <CardWrap width={width}>
      <Swatch color={hex} />
      <Box gridArea="name" p="5" pb="0">
        <CaptionXS>Name</CaptionXS>
        <BodyM>{name}</BodyM>
      </Box>
      <Box gridArea="hex" p="5">
        <CaptionXS>HEX</CaptionXS>
        <BodyM>{hex}</BodyM>
      </Box>
      <Box gridArea="hsl" p="5">
        <CaptionXS>HSL</CaptionXS>
        <BodyM>{hsl}</BodyM>
      </Box>
      <Box gridArea="rgb" p="5" pt="0">
        <CaptionXS>RGB</CaptionXS>
        <BodyM>{rgb}</BodyM>
      </Box>
      <Box gridArea="cmyk" p="5" pt="0">
        <CaptionXS>CMYK</CaptionXS>
        <BodyM>{cmyk}</BodyM>
      </Box>
    </CardWrap>
  );
};

export default ColorCard;
