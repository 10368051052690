import React from 'react';

import { InputField } from '@otovo/rainbow';

export const source = `
import { InputField } from '@otovo/rainbow';

<InputField id="password" label="Password" />
`;

export const render = <InputField id="basic" label="Password" />;
