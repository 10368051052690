import React from 'react';
import { Button } from '@otovo/rainbow';

export const source = `
<Button variant="peach" disabled>
  Primary
</Button>
<Button variant="secondary" disabled>
  Secondary
</Button>
<Button variant="tertiary" disabled>
  Tertiary
</Button>
`;

export const render = (
  <>
    <Button variant="peach" disabled display="block" mb="3">
      Primary
    </Button>
    <Button variant="secondary" disabled display="block" mb="3">
      Secondary
    </Button>
    <Button variant="tertiary" disabled display="block" mb="3">
      Tertiary
    </Button>
  </>
);
