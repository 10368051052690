import React from 'react';
import { H1, BodyM, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';

import * as exampleButton from './examples/exampleButton';
import * as exampleColor from './examples/exampleColor';
import * as exampleIcons1 from './examples/exampleIcons1';
import * as exampleIcons2 from './examples/exampleIcons2';
import * as exampleLink from './examples/exampleLink';
import * as exampleNewTab1 from './examples/exampleNewTab1';
import * as exampleNewTab2 from './examples/exampleNewTab2';
import * as exampleRouter from './examples/exampleRouter';
import * as exampleSize from './examples/exampleSize';

const ButtonsPage = () => (
  <Box>
    <CaptionXS>Link</CaptionXS>
    <H1 as="h2" mb="3">
      Basic usage
    </H1>
    <BodyM mb="6" maxWidth="measure">
      The Link component is rendered as an <Code>a</Code> tag and accepts the{' '}
      <Code>href</Code> prop to specify URL.
    </BodyM>
    <Example {...exampleLink} />
    <HR />

    <H1 as="h2" mb="3">
      Colors
    </H1>
    <BodyM mb="6" maxWidth="measure">
      There are two official link colors: <Code>digital</Code> should be used on
      light backgrounds, while <Code>digitalite</Code> should be used on dark
      backgrounds. The default color is <Code>digital</Code>.
    </BodyM>
    <Example {...exampleColor} />
    <HR />

    <H1 as="h2" mb="3">
      Size
    </H1>
    <BodyM mb="6" maxWidth="measure">
      The prop <Code>fontSize</Code> is used to control the size.
    </BodyM>
    <Example {...exampleSize} />
    <HR />

    <H1 as="h2" mb="3">
      Icons
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Add icons by using the prop <Code>icon={'{svgIcon}'}</Code>.
    </BodyM>
    <Example {...exampleIcons1} />

    <BodyM mb="6" maxWidth="measure">
      <strong>Protip!</strong> By setting the SVGs width and height to{' '}
      <Code>1em</Code> and its fill and stroke to <Code>currentColor</Code> the
      icon will automatically match the links size and color.
    </BodyM>
    <Example {...exampleIcons2} />
    <HR />

    <H1 as="h2" mb="3">
      Open in new tab
    </H1>
    <BodyM mb="6" maxWidth="measure">
      You can make the browser open the link in a new tab by adding{' '}
      <Code>target="_blank"</Code>. Doing so trigger two other changes as well:
      Both an icon and the HTML attribute <Code>rel="noopener"</Code> will
      automatically be added.
    </BodyM>
    <Example {...exampleNewTab1} />

    <BodyM mb="6" maxWidth="measure">
      If the icon <strong>really</strong> doesn't fit, you can remove it by
      adding <Code>icon={'{null}'}</Code>.
    </BodyM>
    <Example {...exampleNewTab2} />
    <HR />

    <H1 as="h2" mb="3">
      As button
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Style buttons as links by adding the props <Code>as="button"</Code> and{' '}
      <Code>onClick={'{...}'}</Code>.
    </BodyM>
    <Example {...exampleButton} />
    <HR />

    <H1 as="h2" mb="3">
      Supporting routing libraries
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Routing libraries like <Code>react-router</Code> and{' '}
      <Code>react-navi</Code> recommend using their built in link components
      instead of regular <Code>a</Code> tags. To support this, consider creating
      a link wrapper instead of using Rainbow <Code>Link</Code> directly.
    </BodyM>
    <Example {...exampleRouter} />
  </Box>
);

export default ButtonsPage;
