import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { compose } from 'styled-system';
import { get, color, space, layout } from '@otovo/rainbow';

import Box from '../../components/Box';
import menuSVG from '../../img/menu.svg';

import Sidebar from './Sidebar';

const Globals = () => (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600|Source+Code+Pro:400,600');

      html {
        box-sizing: border-box;
      }
      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }
      * {
        margin: 0;
        padding: 0;
      }

      body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      strong {
        font-weight: semibold;
      }

      img {
        width: 100%;
      }
    `}
  />
);

const Root = styled.div`
  font-family: ${get('fonts.sansSerif')};
  line-height: ${get('lineHeights.l')};
  color: ${get('colors.text_body')};
`;

const LayoutWrap = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Overlay = styled.div`
  height: 100vh;
  position: relative;
  left: 0;
  z-index: 99;
  transition: transform 0.5s;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    width: ${({ menuOpen }) => (menuOpen ? '100%' : 0)};
    height: ${({ menuOpen }) => (menuOpen ? '100%' : 0)};
    opacity: ${({ menuOpen }) => (menuOpen ? 1 : 0)};
    transition: ${({ menuOpen }) =>
      menuOpen
        ? 'opacity 0.5s'
        : 'opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s'};
  }
`;

const ContentOffset = styled.div`
  height: 100%;
  overflow-y: scroll;
  ${compose(
    color,
    space,
  )}
`;

const Content = styled.div`
  position: relative;
  ${compose(
    color,
    space,
    layout,
  )}
`;

const SidebarWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  background: transparent;
  transition: all 0.5s;
  visibility: ${({ menuOpen }) => (menuOpen ? 'visible' : 'hidden')};
  transform: ${({ menuOpen }) =>
    menuOpen ? 'transform: translate3d(0 , 0, 0)' : 'translate3d(-100%, 0, 0)'};
  ${layout}

  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: ${({ menuOpen }) => (menuOpen ? '100%' : 0)};
    height: ${({ menuOpen }) => (menuOpen ? '100%' : 0)};
    opacity: ${({ menuOpen }) => (menuOpen ? 1 : 0)};
    transition: ${({ menuOpen }) =>
      menuOpen
        ? 'opacity 0.5s'
        : 'opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s'};
  }
`;

const IconButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

const Layout = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const sidebarWidth = '256px';

  const closeMenu = e => {
    const clickedOnOverlay = e.target.attributes.getNamedItem(
      'data-content-wrap',
    );

    if (clickedOnOverlay) {
      setMenuOpen(false);
    }
  };

  return (
    <Root>
      <Globals />

      <LayoutWrap>
        <SidebarWrap width={sidebarWidth} menuOpen={isMenuOpen}>
          <Sidebar width={sidebarWidth} />
        </SidebarWrap>
        <Sidebar width={sidebarWidth} display={{ s: 'none', m3: 'block' }} />
        <Overlay menuOpen={isMenuOpen} data-content-wrap onClick={closeMenu}>
          <ContentOffset ml={{ s: '0', m3: sidebarWidth }}>
            <Content
              p={{ s: '4', m3: '6', l3: '8' }}
              pt={{ m3: '8', l3: '10' }}
              maxWidth="17"
              mx="auto">
              <Box display={{ s: 'block', m3: 'none' }} mb="6">
                <IconButton type="button" onClick={() => setMenuOpen(true)}>
                  <img src={menuSVG} alt="Open menu" />
                </IconButton>
              </Box>

              {children}
            </Content>
          </ContentOffset>
        </Overlay>
      </LayoutWrap>
    </Root>
  );
};

export default Layout;
