import React from 'react';

import Table from '../../../components/Table';

const TableSizesToProps = () => {
  return (
    <Table firstColumn="bold">
      <thead>
        <tr>
          <th>Theme key</th>
          <th>Style props</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>space</td>
          <td>
            margin, marginTop, marginRight, marginBottom, marginLeft, marginX,
            marginY, padding, paddingTop, paddingRight, paddingBottom,
            paddingLeft, paddingX, paddingY, m, mt, mr, mb, ml, mx, my, p, pt,
            pr, pb, pl, px, py, gridGap, gridRowGap, gridColumnGap, top, left,
            bottom, right
          </td>
        </tr>
        <tr>
          <td>sizes</td>
          <td>
            width, height, minWidth, maxWidth, minHeight, maxHeight, flexBasis
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableSizesToProps;
