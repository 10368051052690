export const source = `
.Box-hash {
  height: 128px;
  width: 128px;
  background-color: #04CC83;
}

@media screen and (min-width: 50em) {
  .Box-hash {
    background-color: #3156C4;
  }
}

@media screen and (min-width: 70em) {
  .Box-hash {
    background-color: #E23B3E;
  }
}
`;
