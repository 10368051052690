import React from 'react';
import { Link } from '@otovo/rainbow';

export const source = `
<Link href="#" target="_blank">
  Read more
</Link>
`;

export const render = (
  <Link href="https://www.youtube.com/watch?v=xuCn8ux2gbs" target="_blank">
    Read more
  </Link>
);
