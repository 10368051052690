import React from 'react';
import { Button } from '@otovo/rainbow';

export const source = `
<Button variant="blue">
  Do the thing
</Button>
<Button variant="tertiary">
  Cancel
</Button>
`;

export const render = (
  <>
    <Button variant="blue" display="block" mb="3">
      Do the thing
    </Button>
    <Button variant="tertiary" display="block" mb="3">
      Cancel
    </Button>
  </>
);
