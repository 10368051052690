import React from 'react';

import Table from '../../../components/Table';

const TableMeasure = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>measureNarrow</th>
          <th>measure</th>
          <th>measureWide</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>24em (~45 characters)</td>
          <td>30em (~66 characters)</td>
          <td>34em (~80 characters)</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableMeasure;
