import React from 'react';
import { Link } from '@otovo/rainbow';

export const source = `
<Link as="button" onClick={...}>
  I'm a button!
</Link>
`;

export const render = (
  <Link
    as="button"
    onClick={() =>
      window.alert(
        'Congratulations! You have successfully opened an annoying alert!',
      )
    }>
    I'm a button!
  </Link>
);
