import React from 'react';

import { Box, ErrorMessage } from '@otovo/rainbow';

export const source = `
<Box p="5" bg="darkLilac">
  <ErrorMessage contrastTo="darkBackground">This field is required</ErrorMessage>
</Box>

<Box p="5" bg="lilac_5">
  <ErrorMessage>This field is required</ErrorMessage>
</Box>
`;

export const render = (
  <>
    <Box p="5" bg="darkLilac">
      <ErrorMessage contrastTo="darkBackground">
        This field is required
      </ErrorMessage>
    </Box>
    <Box p="5" bg="lilac_5">
      <ErrorMessage>This field is required</ErrorMessage>
    </Box>
  </>
);
