import React from 'react';

import { Input } from '@otovo/rainbow';

export const source = `
import { Input } from '@otovo/rainbow';

<Input
  name="email"
  value="sun@helios.ra"
  onChange={() => {}} />
`;

export const render = (
  <Input name="email" value="sun@helios.ra" onChange={() => {}} />
);
