import React from 'react';
import { Link } from '@otovo/rainbow';

export const source = `
<Link href="#" target="_blank" icon={null}>
  Read more
</Link>
`;

export const render = (
  <Link
    href="https://www.youtube.com/watch?v=KH-qK5az_5k"
    target="_blank"
    icon={null}>
    Read more
  </Link>
);
