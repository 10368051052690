import React from 'react';
import styled from '@emotion/styled';
import { useActive, useLinkProps } from 'react-navi';

const activeStyles = ({ theme, active }): object => {
  if (active) {
    return {
      color: theme.colors.text_body,
      fontWeight: '500',
      backgroundColor: theme.colors.blue_5,
    };
  }
  return {};
};

const SidebarItem = styled.a(
  ({ theme }): object => ({
    padding: `${theme.space[1]} ${theme.space[2]}`,
    display: 'block',
    textDecoration: 'none',
    color: theme.colors.grey_60,
    fontWeight: 400,
    backgroundColor: 'transparent',
  }),
  activeStyles,
);

function SidebarLink({ children, href, ...rest }) {
  const active = useActive(href);
  const linkProps = useLinkProps({ href });

  return (
    <SidebarItem active={active} children={children} {...rest} {...linkProps} />
  );
}

export default SidebarLink;
