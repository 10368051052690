import React from 'react';
import styled from '@emotion/styled';
import { get, everything } from '@otovo/rainbow';

import Box from './Box';

const InnerTable = styled.table`
  border-radius: ${get('radii.2')};
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  line-height: ${get('lineHeights.l')};

  thead {
    background-color: ${get('colors.grey_5')};
  }

  th,
  td {
    padding: ${get('space.2')} ${get('space.3')};
    text-align: left;
    vertical-align: top;
  }

  td {
    padding: ${get('space.2')} ${get('space.3')};
    border-top: 1px solid ${get('colors.grey_1')};
    font-family: 'Source Code Pro', monospace;
  }

  td:first-of-type {
    font-weight: ${({ firstColumn }) => (firstColumn === 'bold' ? 600 : 400)};
  }

  th {
    padding: ${get('space.3')};
  }
  ${everything}
`;

const Table = ({ children, firstColumn, ...rest }) => (
  <Box
    display="inline-block"
    maxWidth="100%"
    mb="7"
    borderRadius="2"
    boxShadow="0"
    overflowX="scroll"
    {...rest}>
    <InnerTable firstColumn={firstColumn}>{children}</InnerTable>
  </Box>
);

export default Table;
