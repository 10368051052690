import React from 'react';

import { ToggleField } from '@otovo/rainbow';

export const source = `
<ToggleField
  id="labelDescriptionExample"
  label="Learn more about solar"
  labelDescription="And keep me updated about price changes in my area"
/>
`;

export const render = (
  <ToggleField
    id="labelDescriptionExample"
    label="Learn more about solar"
    labelDescription="And keep me updated about price changes in my area"
  />
);
