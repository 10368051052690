import React from 'react';
import { Link, Box } from '@otovo/rainbow';

export const source = `
<Link href="#" fontSize="2">
  Read more
</Link>
<Link href="#" fontSize="4">
  Read more
</Link>
<Link href="#" fontSize="6">
  Read more
</Link>
`;

export const render = (
  <Box display="flex" flexDirection="column" alignItems="flex-start">
    <Link href="https://www.youtube.com/watch?v=JrqzoGD00iM" fontSize="2">
      Read more
    </Link>
    <Link href="https://www.youtube.com/watch?v=QrGrOK8oZG8" fontSize="4">
      Read more
    </Link>
    <Link href="https://www.youtube.com/watch?v=c8icD9XtRhU" fontSize="6">
      Read more
    </Link>
  </Box>
);
