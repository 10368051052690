import React from 'react';

import { CheckboxField } from '@otovo/rainbow';

export const source = `
import { CheckboxField } from '@otovo/rainbow';

<CheckboxField id="exampleBasic" label="Do the thing" />
`;

export const render = <CheckboxField id="exampleBasic" label="Do the thing" />;
