import React from 'react';
import { H1, H3, BodyM, CaptionXS, Link } from '@otovo/rainbow';

import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';
import Table from '../../components/Table';

import * as exampleA1 from './examples/exampleA1';
import * as exampleA2 from './examples/exampleA2';
import * as exampleB from './examples/exampleB';
import * as exampleC from './examples/exampleC';
import * as exampleD from './examples/exampleD';

const AccessingTheThemePage = () => (
  <>
    <CaptionXS as="h1">Accessing the theme</CaptionXS>
    <H1 as="h2" mb="3">
      How to use
    </H1>
    <BodyM mb="4" maxWidth="measure">
      The theme is at the very core of our design system. It defines all the{' '}
      <em>atoms</em> of the component library such as colors, padding, font
      sizes and line height.
    </BodyM>
    <BodyM mb="5" maxWidth="measure">
      There are several ways of accessing theme values. Let's count the ways!
    </BodyM>

    <H3 mb="2">Alternative A: Style props</H3>
    <BodyM mb="3" maxWidth="measure">
      Most Rainbow components can be manipulated using style props.
    </BodyM>
    <Example {...exampleA1} />

    <BodyM mb="5" maxWidth="measure">
      You can also create custom components with style props. To do so you'll
      need to import some categories such as <Code>color</Code> and{' '}
      <Code>space</Code> from Styled System. For an overview of all the
      different categories you can add, see{' '}
      <Link href="https://styled-system.com/table">
        Styled System reference table
      </Link>
      .
    </BodyM>
    <Example {...exampleA2} />

    <H3 mb="2">Alternative B: get()</H3>
    <BodyM mb="3" maxWidth="measure">
      <Code>get()</Code> is a helper function provided by Rainbow. It makes it
      easier to fetch theme values when creating components using Emotion.
    </BodyM>
    <Example {...exampleB} />

    <H3 mb="2">Alternative C: props.theme</H3>
    <BodyM mb="3" maxWidth="measure">
      When using Emotion, the <Code>theme</Code> object is automatically
      provided as a prop. The syntax is slightly more verbose than{' '}
      <Code>get()</Code>, but can be used in some contexts where{' '}
      <Code>get()</Code> can't.
    </BodyM>
    <Example {...exampleC} />

    <H3 mb="2">Alternative D: withTheme</H3>
    <BodyM mb="3" maxWidth="measure">
      While the <Code>theme</Code> is automatically provided when using Emotion
      (as shown above), the same is not the case for regular React components.
      To add the theme to props, use the helper <Code>withTheme()</Code>.
    </BodyM>
    <Example {...exampleD} />

    <HR />

    <CaptionXS>Accessing the theme</CaptionXS>
    <H1 mb="2">Key reference</H1>
    <BodyM mb="5" maxWidth="measure">
      Each part of the theme is connected to one or more style props. The table
      below provides an overview of this mapping.
    </BodyM>

    <Table firstColumn="bold">
      <thead>
        <tr>
          <th>Theme Key</th>
          <th>Style Props</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>space</td>
          <td>
            margin, marginTop, marginRight, marginBottom, marginLeft, marginX,
            marginY, padding, paddingTop, paddingRight, paddingBottom,
            paddingLeft, paddingX, paddingY, m, mt, mr, mb, ml, mx, my, p, pt,
            pr, pb, pl, px, py, gridGap, gridRowGap, gridColumnGap, top, left,
            bottom, right
          </td>
        </tr>
        <tr>
          <td>sizes</td>
          <td>
            width, height, minWidth, maxWidth, minHeight, maxHeight, flexBasis
          </td>
        </tr>
        <tr>
          <td>colors</td>
          <td>color, backgroundColor, bg, borderColor</td>
        </tr>
        <tr>
          <td>gradients</td>
          <td>backgroundImage</td>
        </tr>
        <tr>
          <td>fonts</td>
          <td>fontFamily</td>
        </tr>
        <tr>
          <td>fontSizes</td>
          <td>fontSize</td>
        </tr>
        <tr>
          <td>fontWeights</td>
          <td>fontWeight</td>
        </tr>
        <tr>
          <td>lineHeights</td>
          <td>lineHeight</td>
        </tr>
        <tr>
          <td>letterSpacings</td>
          <td>letterSpacing</td>
        </tr>
        <tr>
          <td>radii</td>
          <td>borderRadius</td>
        </tr>
        {/* <tr>
          <td>borders</td>
          <td>border, borderTop, borderRight, borderBottom, borderLeft</td>
        </tr>
        <tr>
          <td>borderWidths</td>
          <td>borderWidth</td>
        </tr>
        <tr>
          <td>borderStyles</td>
          <td>borderStyle</td>
        </tr>
        <tr>
          <td>shadows</td>
          <td>boxShadow, textShadow</td>
        </tr>
        <tr>
          <td>zIndices</td>
          <td>zIndex</td>
        </tr> */}
      </tbody>
    </Table>
  </>
);

export default AccessingTheThemePage;
