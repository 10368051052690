import React from 'react';
import { mount, route } from 'navi';

import BreakpointsPage from './apps/BreakpointsPage/BreakpointsPage';
import ButtonPage from './apps/ButtonPage/ButtonPage';
import ColorsPage from './apps/ColorsPage/ColorsPage';
import ContentStyleGuidePage from './apps/ContentStyleGuidePage/ContentStyleGuidePage';
import FormHelpersPage from './apps/FormHelpersPage/FormHelpersPage';
import AccessingTheThemePage from './apps/AccessingTheThemePage/AccessingTheThemePage';
import IllustrationsPage from './apps/IllustrationsPage/IllustrationsPage';
import InputFieldPage from './apps/InputFieldPage/InputFieldPage';
import InputPage from './apps/InputPage/InputPage';
import LandingPage from './apps/LandingPage/LandingPage';
import LinkPage from './apps/LinkPage/LinkPage';
import LogoPage from './apps/LogoPage/LogoPage';
import PhotosPage from './apps/PhotosPage/PhotosPage';
import SizesPage from './apps/SizesPage/SizesPage';
import StackedButtonsPage from './apps/StackedButtonsPage/StackedButtonsPage';
import ToggleFieldPage from './apps/ToggleFieldPage/ToggleFieldPage';
import TogglePage from './apps/TogglePage/TogglePage';
import TypographyPage from './apps/TypographyPage/TypographyPage';
import FontSizesPage from './apps/FontSizesPage/FontSizesPage';
import CheckboxPage from './apps/CheckboxPage/CheckboxPage';
import CheckboxFieldPage from './apps/CheckboxFieldPage/CheckboxFieldPage';
import GradientTextPage from './apps/GradientTextPage/GradientTextPage';
import BadgePage from './apps/BadgePage/BadgePage';

const routes = mount({
  '/': route({
    title: '',
    view: <LandingPage />,
  }),

  // Core
  '/core/colors': route({
    title: 'Colors',
    view: <ColorsPage />,
  }),
  '/core/typography': route({
    title: 'Typography',
    view: <TypographyPage />,
  }),
  '/core/logo': route({
    title: 'Logo',
    view: <LogoPage />,
  }),
  '/core/illustrations': route({
    title: 'Illustrations',
    view: <IllustrationsPage />,
  }),
  '/core/photos': route({
    title: 'Photos',
    view: <PhotosPage />,
  }),
  '/core/content-style-guide': route({
    title: 'Content style guide',
    view: <ContentStyleGuidePage />,
  }),

  // Atoms
  '/atoms/accessing-the-theme': route({
    title: 'Accessing the theme',
    view: <AccessingTheThemePage />,
  }),
  '/atoms/breakpoints': route({
    title: 'Breakpoints',
    view: <BreakpointsPage />,
  }),
  '/atoms/sizes': route({
    title: 'Size & space',
    view: <SizesPage />,
  }),
  '/atoms/fontsizes': route({
    title: 'Font sizes',
    view: <FontSizesPage />,
  }),

  // Components
  '/components/button': route({
    title: 'Button',
    view: <ButtonPage />,
  }),
  '/components/stacked-buttons': route({
    title: 'Stacked button',
    view: <StackedButtonsPage />,
  }),
  '/components/link': route({
    title: 'Link',
    view: <LinkPage />,
  }),
  '/components/input': route({
    title: 'Input',
    view: <InputPage />,
  }),
  '/components/inputfield': route({
    title: 'Input field',
    view: <InputFieldPage />,
  }),
  '/components/form-helpers': route({
    title: 'Form',
    view: <FormHelpersPage />,
  }),
  '/components/toggle': route({
    title: 'Toggle',
    view: <TogglePage />,
  }),
  '/components/togglefield': route({
    title: 'Toggle field',
    view: <ToggleFieldPage />,
  }),
  '/components/checkbox': route({
    title: 'Checkbox',
    view: <CheckboxPage />,
  }),
  '/components/checkboxfield': route({
    title: 'Checkbox field',
    view: <CheckboxFieldPage />,
  }),
  '/components/gradient-text': route({
    title: 'Gradient text',
    view: <GradientTextPage />,
  }),
  '/components/badge': route({
    title: 'Badge',
    view: <BadgePage />,
  }),
});

export default routes;
