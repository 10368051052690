import React from 'react';

import { CheckboxField } from '@otovo/rainbow';

export const source = `
<CheckboxField
  layout="right"
  id="…"
  label="…"
/>
`;

export const render = (
  <>
    <CheckboxField
      layout="right"
      id="exampleLayoutRight1"
      label="I want a solar loan"
      mb="4"
    />
    <CheckboxField
      layout="right"
      id="exampleLayoutRight2"
      label="See price after subsidies"
      mb="4"
    />
    <CheckboxField
      layout="right"
      id="exampleLayoutRight3"
      label="I've read the terms and conditions"
      mb="4"
    />
  </>
);
