import React from 'react';

import { Badge } from '@otovo/rainbow';

export const source = `
<Badge fontSize="5" backgroundImage="gradientBlueLight" color="blue_60">
🎵 I'm blue 🎵
</Badge>
`;

export const render = (
  <Badge fontSize="5" backgroundImage="gradientBlueLight" color="blue_60">
    🎵 I'm blue 🎵
  </Badge>
);
