import React from 'react';

import { ToggleField } from '@otovo/rainbow';

export const source = `
import { ToggleField } from '@otovo/rainbow';

<ToggleField id="exampleBasic" label="Do the thing" />
`;

export const render = <ToggleField id="exampleBasic" label="Do the thing" />;
