import React from 'react';

import { Input } from '@otovo/rainbow';

export const source = `
<Input type="password" value="hunter2" />
`;

export const render = (
  <>
    <Input type="password" value="hunter2" onChange={() => {}} />
  </>
);
