import React from 'react';
import { H1, H3, H6, BodyM, CaptionXS, Link } from '@otovo/rainbow';

// import Box from '../../components/Box';
import Code from '../../components/Code';
import Example from '../../components/Example/Example';
import HR from '../../components/HR';
import Ol from '../../components/Ol';
import Alert from '../../components/Alert';

import * as exampleUsage from './examples/exampleUsage';
import TableFontSizes from './tables/TableFontSizes';
import TableFontSizesExtras from './tables/TableFontSizesExtras';

const TypographyAtomsPage = () => {
  return (
    <>
      <CaptionXS as="h1">Font sizes</CaptionXS>
      <H1 as="h2" mb="3">
        Theme values
      </H1>
      <Alert variant="warning" mb="6">
        <H6>Avoid typography atoms</H6>
        <BodyM>
          Even though typography atoms such as "fontSizes" are exposed, try to
          avoid using them. To maintain consistency, use the premade typography
          components whenever possible.
        </BodyM>
      </Alert>
      <BodyM mb="6" maxWidth="measure">
        Rainbow defines the following <strong>font-size scale</strong>:
      </BodyM>
      <TableFontSizes />
      <H3>Extra values</H3>
      <BodyM mb="6" maxWidth="measure">
        In addition to the scale above, Rainbow has a few special font size
        values. These should only be used if you really need them. The reasoning
        behind them is covered in the "design philosophy" section below.
      </BodyM>
      <TableFontSizesExtras />

      <HR />
      <CaptionXS>Font sizes</CaptionXS>
      <H1 as="h2" mb="3">
        Usage
      </H1>
      <BodyM mb="6" maxWidth="measure">
        The scale above is stored in <Code>theme.fontSizes</Code>. For Rainbow
        components, you can use the prop <Code>fontSize</Code> to access it:
      </BodyM>
      <Example {...exampleUsage} />
      <BodyM mb="6" maxWidth="measure">
        For other ways of using the fontSizes scale, see{' '}
        <Link href="/atoms/accessing-the-theme">Accessing the theme</Link>.
      </BodyM>

      <HR />
      <CaptionXS>Font sizes</CaptionXS>
      <H1 as="h2" mb="3">
        Design philosophy
      </H1>
      <BodyM mb="6" maxWidth="measure">
        The font size scale was created with two principles in mind:
      </BodyM>
      <Ol mb="6" ml="6" maxWidth="measure">
        <li>Wax on</li>
        <li>Wax off</li>
      </Ol>
      <BodyM mb="6" maxWidth="measure">
        Whoops! Wrong context. Here's the actual principles:
      </BodyM>
      <Ol mb="6" ml="6" maxWidth="measure">
        <li>
          The scale should be limited enough that every step feels meaningfully
          different than the previous.
        </li>
        <li>
          The scale should support seven levels of headings (H0-H6) with size
          differences between each step.
        </li>
      </Ol>
      <BodyM mb="3" maxWidth="measure">
        These principles are in conflict with each other. The main problem is
        making the large headings responsive. For instance, <Code>H0</Code>{' '}
        should be really big on desktop, but needs to be scaled down to work on
        phones.
      </BodyM>
      <BodyM mb="6" maxWidth="measure">
        The <Code>extra values</Code> seen above were created as a compromise
        between those principles. By pulling the extra values needed to get
        responsive headings to work out, the main scale can remain pure.
      </BodyM>
    </>
  );
};

export default TypographyAtomsPage;
