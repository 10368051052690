import React from 'react';
import styled from '@emotion/styled';

export const source = `
const CircleButton = styled.button\`
  border: 0;
  width: \${props => props.theme.sizes[7]};
  height: \${props => props.theme.sizes[7]};
  border-radius: \${props => props.theme.radii.circle};
  background-color: \${props => props.theme.colors.red_5};
  font-size: \${props => props.theme.fontSizes[5]};
  text-align: center;
  outline: none;

  :hover {
    background-color: \${props => props.theme.colors.red_10};
  }
\`;

<CircleButton>🍄</CircleButton>
`;

const CircleButton = styled.button`
  border: 0;
  width: ${props => props.theme.sizes[7]};
  height: ${props => props.theme.sizes[7]};
  border-radius: ${props => props.theme.radii.circle};
  background-color: ${props => props.theme.colors.red_5};
  font-size: ${props => props.theme.fontSizes[5]};
  text-align: center;
  outline: none;

  :hover {
    background-color: ${props => props.theme.colors.red_10};
  }
`;

export const render = (
  <CircleButton>
    <span role="img" aria-label="mushroom emoji">
      🍄
    </span>
  </CircleButton>
);
