import React from 'react';

import { StackedButton } from '@otovo/rainbow';

export const source = `
import { StackedButton } from '@otovo/rainbow';

const { PrimaryText, SecondaryText } = StackedButton;

return (
  <>
    <StackedButton selected>
      <PrimaryText>Cash</PrimaryText>
      <SecondaryText>€14 000</SecondaryText>
    </StackedButton>

    <StackedButton>
      <PrimaryText>Loan</PrimaryText>
      <SecondaryText>€80/month</SecondaryText>
    </StackedButton>
  </>
);
`;

const ExamplePrimaryText = () => {
  const { PrimaryText, SecondaryText } = StackedButton;

  return (
    <>
      <StackedButton selected>
        <PrimaryText>Cash</PrimaryText>
        <SecondaryText>€14 000</SecondaryText>
      </StackedButton>

      <StackedButton>
        <PrimaryText>Loan</PrimaryText>
        <SecondaryText>€80/month</SecondaryText>
      </StackedButton>
    </>
  );
};

export const render = <ExamplePrimaryText />;
