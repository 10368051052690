import React from 'react';

import { InputField } from '@otovo/rainbow';

export const source = `
<InputField
  id="errorMessageExample"
  label="Password"
  validationState="error"
  errorMessage="This field is required"
/>
`;

export const render = (
  <InputField
    id="errorMessageExample"
    label="Password"
    validationState="error"
    errorMessage="This field is required"
  />
);
