import React from 'react';

import { Form, Button, Input } from '@otovo/rainbow';

export const source = `
<Form onSubmit={() => alert('Submitted!')}>
  <Input required type="text" />
  <Button type="submit"></Button>
</Form>
`;

export const render = (
  <Form
    onSubmit={event => {
      event.preventDefault();
      alert(`Submitted: ${event.currentTarget['required-example'].value}`);
    }}>
    <Input
      id="required-example"
      name="required-example"
      required
      placeholder="You can't skip me!"
      type="text"
      mb="3"
    />
    <Button type="submit">Submit</Button>
  </Form>
);
