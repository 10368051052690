import React from 'react';

import { StackedButton } from '@otovo/rainbow';

export const source = `
import { StackedButton } from '@otovo/rainbow';
const { PrimaryText } = StackedButton;
return (
  <>
    <StackedButton>
      <PrimaryText>Standard</PrimaryText>
    </StackedButton>

    <StackedButton>
      <PrimaryText>Premium</PrimaryText>
    </StackedButton>
  </>
);
`;

const ExamplePrimaryText = () => {
  const { PrimaryText } = StackedButton;
  return (
    <>
      <StackedButton>
        <PrimaryText>Standard</PrimaryText>
      </StackedButton>

      <StackedButton>
        <PrimaryText>Premium</PrimaryText>
      </StackedButton>
    </>
  );
};

export const render = <ExamplePrimaryText />;
