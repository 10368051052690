import React from 'react';

import { Input, Label, LabelDescription, ErrorMessage } from '@otovo/rainbow';

export const source = `
import { ErrorMessage, Input, Label, LabelDescription } from '@otovo/rainbow';

<Label htmlFor="password">Password</Label>
<LabelDescription id="password-description" mb="2">
  Must have at least 6 characters
</LabelDescription>
<Input
  id="password"
  aria-describedby="password-error password-description"
  validationState="error"
/>
<ErrorMessage id="password-error" mt="2">
  This field is required
</ErrorMessage>
`;

export const render = (
  <>
    <Label htmlFor="password2">Password</Label>
    <LabelDescription id="password2-description" mb="2">
      Must have at least 6 characters
    </LabelDescription>
    <Input
      id="password2"
      aria-describedby="password2-error password2-description"
      validationState="error"
    />
    <ErrorMessage id="password2-error" mt="2">
      This field is required
    </ErrorMessage>
  </>
);
