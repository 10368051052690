import React from 'react';
import { H1, H3, BodyM, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';
import Code from '../../components/Code';
import Example from '../../components/Example/Example';
import HR from '../../components/HR';
import Quote from '../../components/Quote';

import TableSizeComparison from './tables/TableSizeComparison';
import TableSizesToProps from './tables/TableSizesToProps';
import TableMeasure from './tables/TableMeasure';
import TableAlternativeScales from './tables/TableAlternativeScales';
import scaleIllustration from './scaleIllustration.svg';

import * as exampleUsage from './examples/exampleUsage';

const SizesPage = () => (
  <Box>
    <CaptionXS as="h1">Size &amp; space</CaptionXS>
    <H1 as="h2" mb="3">
      Theme values
    </H1>
    <BodyM mb="6" maxWidth="measure">
      The table below shows the Rainbow size scale defined in pixels (as well as
      the Tachyons ones for reference):
    </BodyM>
    <TableSizeComparison />

    <H3 mb="2">Negative values</H3>
    <BodyM mb="6" maxWidth="measure">
      Negative values are access by putting an underscore before the scale
      value, e.g. <Code>"_4"</Code> means <Code>-16px</Code>. This is useful for
      placing absolutely positioned blocks, or for negative margins.
    </BodyM>

    <H3 mb="2">Measure</H3>
    <BodyM mb="6" maxWidth="measure">
      In addition to the scale above, Rainbow has a few special size values
      called <Code>measure</Code>. There are three in total:
    </BodyM>

    <TableMeasure />

    <BodyM mb="4" maxWidth="measure">
      The term measure refers to the length of a line of text which is one of
      the most important aspects of readability.
    </BodyM>

    <Quote author="Robert Bringhurst, The Elements of Typographic Style">
      Anything from 45 to 75 characters is widely regarded as a satisfactory
      length of line for a single-column page… the 66-character line (counting
      both letters and spaces) is widely regarded as ideal. For multiple-column
      work, a better average is 40-50 characters.
    </Quote>

    <HR />

    <CaptionXS as="h1">Size &amp; space</CaptionXS>
    <H1 as="h2" mb="3">
      Usage
    </H1>

    <BodyM mb="6" maxWidth="measure">
      The scale above is tied to two theme keys: <Code>sizes</Code> and{' '}
      <Code>space</Code>. These keys are again tied to the following props:
    </BodyM>
    <TableSizesToProps />

    <BodyM mb="6" maxWidth="measure">
      For most components you can reference the scale and measure values like
      this:
    </BodyM>

    <Example {...exampleUsage} />

    <HR />

    <CaptionXS as="h1">Size &amp; space</CaptionXS>
    <H1 as="h2" mb="3">
      Design philosophy
    </H1>
    <BodyM mb="3" maxWidth="measure">
      Creating good size scales is easier said than done. The scale should be
      limited enough that every step feels meaningfully different than the last.
      At the same time, it shouldn't be so limited that people constantly need
      values that doesn't exist.
    </BodyM>

    <BodyM mb="6" maxWidth="measure">
      Let's take a look at some alternative scales before discussing the design
      of Rainbow's scale.
    </BodyM>

    <H3 mb="3">Alternative scales</H3>
    <BodyM mb="6" maxWidth="measure">
      Three common ways of creating size scales are linear, ratio and
      exponential. They all have advantages and disadvantages:
    </BodyM>
    <TableAlternativeScales />

    <BodyM mb="3" maxWidth="measure">
      At first glance, a <strong>linear scale</strong> look great. It's easy to
      reason about and produces many useful values at the lower end. However,
      towards the end of the scale it becomes basically useless. The values are
      simply too close together and will likely lead to inconsistent design.
    </BodyM>
    <BodyM mb="3" maxWidth="measure">
      A <strong>ratio</strong> based scale solves the issue mentioned above.
      Instead of a fixed gap between values, it uses relative difference between
      adjacent values ("always grow 33% per value"). Unfortunately, it often
      ends up being too crowded in the lower part while still missing useful
      values in the high range. Another problem is that the values aren't
      integers, which might cause trouble for attributes that doesn't support
      subpixels rendering.
    </BodyM>
    <BodyM mb="6" maxWidth="measure">
      <strong>Exponential scales</strong> double the value for every step. One
      advantage of this is composability. Since web elements are often nested,
      keeping the paddings consistent can be a challenge. However, a scale based
      on doubling makes this a breeze. Another advantage is that all the steps
      are integers (hurray 🎉). The main problem of exponential scales is the
      steep ramp-up in size towards the end. Need a value between 512px and
      1024px? Too bad!
    </BodyM>

    <H3 mb="3">Rainbow's scale</H3>
    <BodyM mb="3" maxWidth="measure">
      Rainbow's scale is created using multiple exponential scales. More
      specifically, it has <strong>three base values</strong>: 4, 12 and 640.
      All the values behave nicely when divided by 16.
    </BodyM>
    <BodyM mb="6" maxWidth="measure">
      This approach keeps most of the advantages from a single exponential
      scale, while patching its shortcomings. At the cost of a bit of
      mathematical purity, we get a scale with a more useful spread of values.
    </BodyM>

    <img src={scaleIllustration} alt="" />
  </Box>
);

export default SizesPage;
