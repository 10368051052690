import React from 'react';

import { InputField } from '@otovo/rainbow';

export const source = `
<InputField
  id="labelDescriptionExample"
  label="Password"
  labelDescription="Must contain at least 6 characters"
/>
`;

export const render = (
  <InputField
    id="labelDescriptionExample"
    label="Password"
    labelDescription="Must contain at least 6 characters"
  />
);
