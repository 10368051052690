import React from 'react';
import styled from '@emotion/styled';
import { compose } from 'styled-system';
import { color, border, space } from '@otovo/rainbow';

export const source = `
import styled from '@emotion/styled';
import { compose } from 'styled-system';
import { color, border, space } from '@otovo/rainbow';

const Success = styled.div\`
  \${compose(
    border, 
    color,  
    space,
  )}
\`;

<Success bg="green_5" borderRadius="2" p="3" color="green_90">
  <strong>Yay!</strong> My custom success!
</Success>
`;

const Success = styled.div`
  ${compose(
    border,
    color,
    space,
  )}
`;

export const render = (
  <Success bg="green_5" borderRadius="2" p="3" color="green_90">
    <strong>Yay!</strong> My custom success!
  </Success>
);
