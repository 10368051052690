import React from 'react';
import { H0, H4, BodyM, CaptionXS, Button, H6 } from '@otovo/rainbow';

import Box from '../../components/Box';
import Alert from '../../components/Alert';

const Row = ({ children }) => (
  <Box
    display="grid"
    gridTemplateColumns={{ s: '1fr', m: '1fr 1fr' }}
    gridGap="6"
    mb="7"
    alignItems="stretch">
    {children}
  </Box>
);

const LandingPage = () => (
  <Box>
    <CaptionXS>Design System</CaptionXS>
    <H0 mb="6">
      Rainbow{' '}
      <span role="img" aria-label="rainbow emoji">
        🌈
      </span>
    </H0>
    <Alert variant="warning" mb="6">
      <H6>Otovo is rebranding</H6>
      <BodyM>
        Rainbow is in the process of upgrading, and some part of this
        documentation might be outdated.
      </BodyM>
    </Alert>
    <BodyM mb="3" maxWidth="measure">
      Rainbow is Otovo's design system. It consist of three parts: A design
      language, a design kit and a component library.
    </BodyM>
    <BodyM maxWidth="measure" mb="6">
      The <strong>design language</strong> serves as the foundation of Rainbow.
      It defines characteristics such as typography, colors, icons, spacing,
      voice and tone, animations, illustrations and photography. In other words:
      The very essence of our brand. These definitions is documented on this
      site.
    </BodyM>

    <Row>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        boxShadow="0"
        p="6">
        <div>
          <H4 as="h3" mb="3">
            Design
          </H4>
          <BodyM mb="5">
            The <strong>UI Kit</strong> is makes it easy to create mockups for
            new designs. It contains shared styles, symbols and components based
            on our design language. There are two version: One for Sketch and
            one for Keynote.
          </BodyM>
        </div>
        <Button
          as="a"
          href="https://github.com/otovo/rainbow-documentation/files/3559050/Rainbow.UI.Kit.zip"
          variant="secondary">
          Download UI Kit
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        boxShadow="0"
        p="6">
        <div>
          <H4 as="h3" mb="3">
            Development
          </H4>
          <BodyM mb="5">
            The <strong>component library</strong> helps turning design into
            code. It's only available for for React projects. Click below for
            setup instructions.
          </BodyM>
        </div>
        <Button
          as="a"
          href="https://github.com/otovo/rainbow#getting-started"
          variant="secondary">
          Get started
        </Button>
      </Box>
    </Row>
  </Box>
);

export default LandingPage;
