import React from 'react';
import { Button } from '@otovo/rainbow';

export const source = `
<Button variant="blue">
  Yes, please
</Button>
<Button variant="secondary">
  No thanks
</Button>
`;

export const render = (
  <>
    <Button variant="blue" display="block" mb="3">
      Yes, please
    </Button>
    <Button variant="secondary" display="block" mb="3">
      No thanks
    </Button>
  </>
);
