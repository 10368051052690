import React from 'react';

import { CheckboxField } from '@otovo/rainbow';

export const source = `
<CheckboxField
  id="labelDescriptionExample"
  label="Learn more about solar"
  labelDescription="And keep me updated about price changes in my area"
/>
`;

export const render = (
  <CheckboxField
    id="labelDescriptionExample"
    label="Learn more about solar"
    labelDescription="And keep me updated about price changes in my area"
  />
);
