import styled from '@emotion/styled';
import { everything, get } from '@otovo/rainbow';

const Ol = styled.ol`
  list-style: none;
  counter-reset: rainbowCounter;

  li {
    counter-increment: rainbowCounter;
    position: relative;
    padding-left: ${get('space.5')};
    margin-bottom: ${get('space.3')};
  }

  li::before {
    content: counter(rainbowCounter) '. ';
    color: ${get('colors.text_heading')};
    font-weight: 600;
    position: absolute;
    left: 0;
    font-size: ${get('fontSizes.5')};
    top: -4px;
  }

  ${everything}
`;

export default Ol;
