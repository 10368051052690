import React from 'react';
import { Button } from '@otovo/rainbow';

import ArrowRight from './IconArrowRight';

export const source = `
const ArrowRight = (
  <svg
    width="1em"
    height="1em"
    fill="currentColor"
    fillRule="evenodd"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path d="..." />
  </svg>
);

return (
  <>
    <Button icon={<ArrowRight />} variant="blue">
      Continue
    </Button>
    <Button icon={<ArrowRight />} variant="peach">
      Continue
    </Button>
    <Button icon={<ArrowRight />} variant="secondary">
      Continue
    </Button>
    <Button icon={<ArrowRight />} variant="tertiary">
      Continue
    </Button>
  </>
);
`;

export const render = (
  <>
    <Button icon={<ArrowRight />} variant="blue" display="flex" mb="2">
      Continue
    </Button>
    <Button icon={<ArrowRight />} variant="peach" display="flex" mb="2">
      Continue
    </Button>
    <Button icon={<ArrowRight />} variant="secondary" display="flex" mb="2">
      Continue
    </Button>
    <Button icon={<ArrowRight />} variant="tertiary" display="flex" mb="2">
      Continue
    </Button>
  </>
);
