import React from 'react';
import { H0, BodyL } from '@otovo/rainbow';

import Box from '../../components/Box';

const NotFoundPage = () => {
  return (
    <Box H0="flex" flexDirection="column" textAlign="center">
      <H0>404</H0>
      <BodyL>The computer says no.</BodyL>
    </Box>
  );
};

export default NotFoundPage;
