import React from 'react';
import { Link, Box } from '@otovo/rainbow';

import IconChevronRight from './IconChevronRight';

export const source = `
const IconChevronRight = (
  <svg
    width="1em"
    height="1em"
    stroke="currentColor"
    ...>
    <polyline points="…"></polyline>
  </svg>
);

<Link href="#" fontSize="2" icon={IconChevronRight}>
  Next
</Link>
<Link href="#" fontSize="4" icon={IconChevronRight}>
  Next
</Link>
<Link href="#" fontSize="6" icon={IconChevronRight}>
  Next
</Link>
`;

export const render = (
  <>
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Link
        href="https://www.youtube.com/watch?v=ZTidn2dBYbY"
        fontSize="2"
        icon={IconChevronRight}>
        Next
      </Link>
      <Link
        href="https://www.youtube.com/watch?v=F3jFTzhdZF4"
        fontSize="4"
        icon={IconChevronRight}>
        Next
      </Link>
      <Link
        href="https://www.youtube.com/watch?v=ba9k5SWwE38"
        fontSize="6"
        icon={IconChevronRight}>
        Next
      </Link>
    </Box>
  </>
);
