import React from 'react';

import { StackedButton } from '@otovo/rainbow';

export const source = `
import { StackedButton } from '@otovo/rainbow';
const { SecondaryText } = StackedButton;

return (
  <>
    <StackedButton>
      <SecondaryText>Right aligned</SecondaryText>
    </StackedButton>

    <StackedButton>
      <SecondaryText ml="0">Left aligned</SecondaryText>
    </StackedButton>
  </>
);
`;

const ExampleSecondaryText = () => {
  const { SecondaryText } = StackedButton;
  return (
    <>
      <StackedButton>
        <SecondaryText>Right aligned</SecondaryText>
      </StackedButton>

      <StackedButton>
        <SecondaryText ml="0">Left aligned</SecondaryText>
      </StackedButton>
    </>
  );
};

export const render = <ExampleSecondaryText />;
