import React from 'react';

import { GradientText, H2 } from '@otovo/rainbow';

export const source = `
import { GradientText, H2 } from '@otovo/rainbow';

return (
  <>
  <H2>
    <GradientText>Solar power</GradientText> to the people
  </H2>
  </>
);
`;

export const render = (
  <H2 as="p">
    <GradientText>Solar power</GradientText> to the people
  </H2>
);
