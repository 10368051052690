import React from 'react';
import { H1, H3, BodyM, CaptionXS, Link } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';

import * as exampleLabel from './examples/exampleLabel';
import * as exampleLabelContrastTo from './examples/exampleLabelContrastTo';
import * as exampleLabelDescription from './examples/exampleLabelDescription';
import * as exampleLabelDescriptionContrastTo from './examples/exampleLabelDescriptionContrastTo';
import * as exampleErrorMessage from './examples/exampleErrorMessage';
import * as exampleErrorMessageContrastTo from './examples/exampleErrorMessageContrastTo';
import * as exampleFormDark from './examples/exampleFormDark';
import * as exampleFormLight from './examples/exampleFormLight';

const FormHelpersPage = () => (
  <Box>
    <CaptionXS as="h1">Form helpers</CaptionXS>
    <H1 as="h2" mb="3">
      Label
    </H1>
    <BodyM mb="6" maxWidth="measure">
      <Code>Label</Code> is used to describe an input. Remember to add a{' '}
      <Code>htmlFor</Code> to the label and an <Code>id</Code> to the
      corresponding element.
    </BodyM>
    <Example {...exampleLabel} />

    <H3 mb="2">ContrastTo</H3>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>contrastTo</Code> prop to ensure good contrast against the
      background. Two values are accepted: <Code>lightBackground</Code>{' '}
      (default) and <Code>darkBackground</Code>.
    </BodyM>
    <Example {...exampleLabelContrastTo} />

    <HR />

    <CaptionXS>Form helpers</CaptionXS>
    <H1 as="h2" mb="3">
      Label description
    </H1>
    <BodyM mb="3" maxWidth="measure">
      Use <Code>LabelDescription</Code> to add help text below a label. Prefer
      label descriptions over placeholders due to{' '}
      <Link href="https://www.nngroup.com/articles/form-design-placeholders/">
        weaknesses in placeholders
      </Link>
      .
    </BodyM>
    <BodyM mb="6" maxWidth="measure">
      <strong>Accessibility tip!</strong> Add an <Code>id</Code> to the
      description and connect it to the input using{' '}
      <Code>aria-describedby</Code>. Doing so makes screen readers read the help
      text when the user enters the input element.
    </BodyM>
    <Example {...exampleLabelDescription} />

    <H3 mb="2">ContrastTo</H3>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>contrastTo</Code> prop to ensure good contrast against the
      background. Two values are accepted: <Code>lightBackground</Code>{' '}
      (default) and <Code>darkBackground</Code>.
    </BodyM>
    <Example {...exampleLabelDescriptionContrastTo} />

    <HR />

    <CaptionXS>Form helpers</CaptionXS>
    <H1 as="h2" mb="3">
      Error message
    </H1>
    <BodyM mb="3" maxWidth="measure">
      <Code>ErrorMessage</Code> is used to display validation errors.
    </BodyM>
    <BodyM mb="3" maxWidth="measure">
      <strong>Accessibility tip!</strong> Add an <Code>id</Code> to the error
      message and connect it to the input using <Code>aria-describedby</Code>.
      Doing so makes screen readers read the help text when the user enters the
      input element.
    </BodyM>

    <BodyM mb="6" maxWidth="measure">
      If multiple elements describe the input, add all their ids to{' '}
      <Code>aria-describedby</Code> as a space separated string. The error
      message should be the first id listed. For more, see{' '}
      <Link href="https://medium.com/pulsar/creating-accessible-input-errors-and-help-text-74c7e00e757a">
        this
      </Link>
      .
    </BodyM>

    <Example {...exampleErrorMessage} />

    <H3 mb="2">ContrastTo</H3>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>contrastTo</Code> prop to ensure good contrast against the
      background. Two values are accepted: <Code>lightBackground</Code>{' '}
      (default) and <Code>darkBackground</Code>.
    </BodyM>
    <Example {...exampleErrorMessageContrastTo} />

    <HR />

    <CaptionXS>Form helpers</CaptionXS>
    <H1 as="h2" mb="3">
      Form
    </H1>
    <BodyM mb="6" maxWidth="measure">
      <Code>Form</Code> is a thin wrapper around the regular HTML form element.
      The only additional functionality it provides is the{' '}
      <Code>contrastTo</Code> prop. When using the it, all direct descendents of{' '}
      <Code>Form</Code> will receive the contrastTo prop.
    </BodyM>

    <Example {...exampleFormDark} />
    <Example {...exampleFormLight} />
  </Box>
);

export default FormHelpersPage;
