import React from 'react';

import Table from '../../../components/Table';

const TableAlternativeScales = () => {
  return (
    <Table firstColumn="bold">
      <thead>
        <tr>
          <th>Type</th>
          <th>Scale</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Linear</td>
          <td>4 8 12 16 20 24 28 32 36 40 44 48 52</td>
        </tr>
        <tr>
          <td>Ratio (e.g. 3:4)</td>
          <td>3.8 5.1 6.8 12 16 21.3 28.4 37.9 50.5 67.3 89.8</td>
        </tr>
        <tr>
          <td>
            Exponential (e.g. 2<sup>x</sup>)
          </td>
          <td>4 8 16 32 64 128 256 512 1024</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableAlternativeScales;
