import React from 'react';

import { CheckboxField } from '@otovo/rainbow';

export const source = `
<CheckboxField
  layout="vertical"
  id="…"
  label="…"
/>
`;

export const render = (
  <>
    <CheckboxField
      layout="vertical"
      id="exampleLayoutVertical1"
      label="I want a solar loan"
      mb="6"
    />
    <CheckboxField
      layout="vertical"
      id="exampleLayoutVertical2"
      label="See price after subsidies"
      mb="6"
    />
    <CheckboxField
      layout="vertical"
      id="exampleLayoutVertical3"
      label="I've read the terms and conditions"
      mb="6"
    />
  </>
);
