// Source: http://www.easyrgb.com/en/math.php
// Source: https://css-tricks.com/converting-color-spaces-in-javascript/
function rgbToCmy(rgb) {
  let ex = /^rgb\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){2}|((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s)){2})((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]))|((((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){2}|((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){2})(([1-9]?\d(\.\d+)?)|100|(\.\d+))%))\)$/i;
  if (ex.test(rgb)) {
    // choose correct separator
    let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
    // turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb
      .substr(4)
      .split(')')[0]
      .split(sep);

    // convert %s to 0–255
    for (let R in rgb) {
      let r = rgb[R];
      if (r.indexOf('%') > -1)
        rgb[R] = Math.round((r.substr(0, r.length - 1) / 100) * 255);
      /* Example:
          75% -> 191
          75/100 = 0.75, * 255 = 191.25 -> 191
          */
    }

    let r = +rgb[0],
      g = +rgb[1],
      b = +rgb[2];

    //R, G and B input range = 0 ÷ 255
    //C, M and Y output range = 0 ÷ 1.0
    const c = 1 - r / 255;
    const m = 1 - g / 255;
    const y = 1 - b / 255;

    return { c, m, y };
  } else {
    return 'Invalid input color';
  }
}

function cmyToCmyk({ c, m, y }) {
  //C, M, Y and K range = 0 ÷ 1.0

  let k = 1;

  if (c < k) k = c;
  if (m < k) k = m;
  if (y < k) k = y;

  if (k === 1) {
    c = 0; //Black only
    m = 0;
    y = 0;
  } else {
    c = (c - k) / (1 - k);
    m = (m - k) / (1 - k);
    y = (y - k) / (1 - k);
  }

  const [cc, mm, yy, kk] = [c, m, y, k].map(n => Math.round(n * 100));

  return `cmyk(${cc},${mm},${yy},${kk})`;
}

export default function rgbToCmyk(rgb) {
  const cmy = rgbToCmy(rgb);
  return cmyToCmyk(cmy);
}
