import React, { useState } from 'react';

import { Toggle, BodyS } from '@otovo/rainbow';

export const source = `
const [checked, setChecked] = useState(false);

return (
  <>
    <Toggle
      checked={checked}
      onChange={e => setChecked(e.target.checked)}
      id="exampleInteractive"
    />
    <BodyS mt="3" ml="1">
      The toggle is <strong>{checked ? 'on' : 'off'}</strong>
    </BodyS>
  </>
);
`;

const InputDemo = () => {
  const [checked, setChecked] = useState(false);

  return (
    <>
      <Toggle
        checked={checked}
        onChange={e => setChecked(e.target.checked)}
        id="exampleInteractive"
      />
      <BodyS mt="3" ml="1">
        The toggle is <strong>{checked ? 'on' : 'off'}</strong>
      </BodyS>
    </>
  );
};

export const render = <InputDemo />;
