import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { withTheme } from 'emotion-theming';

import syntaxHighlightingTheme from './syntaxHighlightingTheme';

import Box from '../../components/Box';

const SyntaxHighlighterWrapper = ({ language, source, theme }) => (
  <SyntaxHighlighter
    language={language}
    style={syntaxHighlightingTheme}
    customStyle={{
      margin: 0,
      height: '100%',
      padding: theme.space[5],
    }}>
    {source.trim()}
  </SyntaxHighlighter>
);

const Example = ({ render, source, language = 'jsx', theme }) => {
  if (!!render && !!source) {
    return (
      <Box mb="7" boxShadow="0" display="flex" flexWrap="wrap">
        <Box width={{ s: '100%', m2: '50%' }} p="5">
          {render}
        </Box>
        <Box width={{ s: '100%', m2: '50%' }}>
          <SyntaxHighlighterWrapper
            language={language}
            theme={theme}
            source={source}
          />
        </Box>
      </Box>
    );
  } else if (!!source) {
    return (
      <Box mb="7" boxShadow="0">
        <SyntaxHighlighterWrapper
          language={language}
          theme={theme}
          source={source}
        />
      </Box>
    );
  }

  return (
    <Box bg="red_5" color="red_100" mb="5" p="3">
      Error: Missing props <strong>source</strong> in &lt;Example /&gt;
    </Box>
  );
};

export default withTheme(Example);
