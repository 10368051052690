import React from 'react';
import { H1, H3, BodyM, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';

import * as exampleBasic from './examples/exampleBasic';
import * as exampleSingleSelect from './examples/exampleSingleSelect';
import * as exampleMultiSelect from './examples/exampleMultiSelect';
import * as examplePrimaryText from './examples/examplePrimaryText';
import * as exampleSecondaryText from './examples/exampleSecondaryText';
import * as exampleDot from './examples/exampleDot';
import * as exampleCustom from './examples/exampleCustom';

const ButtonsPage = () => (
  <Box>
    <CaptionXS as="h1">Stacked buttons</CaptionXS>
    <H1 as="h2" mb="3">
      Basic usage
    </H1>
    <BodyM mb="6" maxWidth="measure">
      The <Code>StackedButton</Code> should be used when presenting the user
      with a choice.
    </BodyM>
    <Example {...exampleBasic} />
    <HR />

    <CaptionXS>Stacked buttons</CaptionXS>
    <H1 as="h2" mb="3">
      Making it interactive
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>selected</Code> and <Code>onClick</Code> props to make the
      buttons interactive. Depending on your implementation, the buttons can
      behave as either "single select" or "multi select".
    </BodyM>
    <H3 mb="3">Single select</H3>
    <Example {...exampleSingleSelect} />
    <H3 mb="3">Multi select</H3>
    <Example {...exampleMultiSelect} />
    <HR />

    <CaptionXS>Stacked buttons</CaptionXS>
    <H1 as="h2" mb="3">
      Subcomponents
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Instead of adding "thousands" of props to<Code>StackedButton</Code>, the
      content is styled using subcomponents (as in{' '}
      <Code>StackedButton.SomeComponent</Code>). This allows for greater
      flexiblity while keeping the complexity low.
    </BodyM>

    <H3 mb="3">Primary text</H3>
    <Example {...examplePrimaryText} />

    <H3 mb="3">Secondary text</H3>
    <BodyM mb="6" maxWidth="measure">
      By default <Code>SecondaryText</Code> will be right aligned. This
      behaviour can be changed by overriding the <Code>ml</Code> prop.
    </BodyM>
    <Example {...exampleSecondaryText} />

    <H3 mb="3">Dot</H3>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>color</Code> prop to set the color.
    </BodyM>
    <Example {...exampleDot} />
    <HR />

    <CaptionXS>Stacked buttons</CaptionXS>
    <H1 as="h2" mb="3">
      Custom content
    </H1>
    <BodyM mb="6" maxWidth="measure">
      If the default style doesn't do it for you, you can{' '}
      <Box textDecoration="line-through" as="span">
        ruin it
      </Box>{' '}
      adapt it to your needs.
    </BodyM>
    <Example {...exampleCustom} />
  </Box>
);

export default ButtonsPage;
