import React from 'react';
import { Button } from '@otovo/rainbow';

import ArrowRight from './IconArrowRight';

export const source = `
import IconArrowRight from 'some/folder';

<Button shape="pill" icon={<ArrowRight />}>
  Pill
</Button>
<Button shape="circle" icon={<ArrowRight />}>
  Circle
</Button>
`;

export const render = (
  <>
    <Button icon={<ArrowRight />} shape="pill" mr="2">
      Pill
    </Button>
    <Button icon={<ArrowRight />} shape="circle">
      Circle
    </Button>
  </>
);
