import React from 'react';

import { Toggle, Box } from '@otovo/rainbow';

export const source = `
<Box width="50%" p="5" bg="darkLilac">
  <Toggle disabled contrastTo="darkBackground" mb="2" id="exampleDisabled1" />
  <Toggle disabled contrastTo="darkBackground" checked id="exampleDisabled2" />
</Box>

<Box width="50%" p="5" bg="lilac_5">
  <Toggle disabled contrastTo="lightBackground" mb="2" id="exampleDisabled3" />
  <Toggle disabled contrastTo="lightBackground" checked id="exampleDisabled4" />
</Box>
`;

export const render = (
  <>
    <Box display="flex" width="100%">
      <Box width="50%" p="5" bg="darkLilac">
        <Toggle
          disabled
          contrastTo="darkBackground"
          mb="2"
          id="exampleDisabled1"
        />
        <Toggle
          disabled
          contrastTo="darkBackground"
          checked
          id="exampleDisabled2"
        />
      </Box>
      <Box width="50%" p="5" bg="lilac_5">
        <Toggle
          disabled
          contrastTo="lightBackground"
          mb="2"
          id="exampleDisabled3"
        />
        <Toggle
          disabled
          contrastTo="lightBackground"
          checked
          id="exampleDisabled4"
        />
      </Box>
    </Box>
  </>
);
