import React from 'react';

import { StackedButton, Text } from '@otovo/rainbow';

export const source = `
<StackedButton justifyContent="center" bg="#EAE1BE">
  <Text fontFamily="Comic Sans MS" color="#665412" fontWeight="bold">
    Please write in my guest book! 📓
  </Text>
</StackedButton>
`;

export const render = (
  <StackedButton justifyContent="center" bg="#EAE1BE">
    <Text fontFamily="Comic Sans MS" color="#665412" fontWeight="bold">
      Please write in my guest book!{' '}
      <span role="img" aria-label="book emoji">
        📓
      </span>
    </Text>
  </StackedButton>
);
