import React from 'react';
import { Box, BodyM } from '@otovo/rainbow';

export const source = `
<Box p="4" maxWidth="measureNarrow" bg="lilac_5">
  <BodyM>
    We're no strangers to love. You know the rules and so do I. A full
    commitment's what I'm thinking of. You wouldn't get this from any other
    guy
  </BodyM>
</Box>
`;

export const render = (
  <Box p="4" maxWidth="measureNarrow" bg="lilac_5">
    <BodyM>
      We're no strangers to love. You know the rules and so do I. A full
      commitment's what I'm thinking of. You wouldn't get this from any other
      guy
    </BodyM>
  </Box>
);
