import React from 'react';
import { Button } from '@otovo/rainbow';

export const source = `
<Button variant="blue">
  160px wide
</Button>
<Button variant="blue">
  I'm a bit larger than that!
</Button>
<Button variant="blue" width="100%">
  Do you even lift, bro?
</Button>
`;

export const render = (
  <>
    <Button variant="blue" mr="3" mb="3">
      160px wide
    </Button>
    <Button variant="blue" mr="3" mb="3">
      I'm a bit larger than that!
    </Button>
    <Button variant="blue" width="100%" mr="3" mb="3">
      Do you even lift, bro?
    </Button>
  </>
);
