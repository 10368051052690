import React from 'react';

import { Box, Checkbox } from '@otovo/rainbow';

export const source = `
<Box width="50%" p="5" bg="darkLilac">
  <Checkbox disabled contrastTo="darkBackground" id="exampleDisabled1" checked={false} />
  <Checkbox disabled contrastTo="darkBackground" id="exampleDisabled2" checked />
</Box>

<Box width="50%" p="5" bg="lilac_5">
  <Checkbox disabled contrastTo="lightBackground" id="exampleDisabled3" checked={false} />
  <Checkbox disabled contrastTo="lightBackground" id="exampleDisabled4" checked />
</Box>
`;

export const render = (
  <>
    <Box display="flex" width="100%">
      <Box width="50%" p="5" bg="darkLilac">
        <Checkbox
          contrastTo="darkBackground"
          id="exampleDisabled1"
          mb="2"
          checked={false}
          disabled
        />
        <Checkbox
          contrastTo="darkBackground"
          id="exampleDisabled2"
          mb="2"
          checked
          disabled
        />
      </Box>
      <Box width="50%" p="5" bg="lilac_5">
        <Checkbox
          contrastTo="lightBackground"
          id="exampleDisabled3"
          mb="2"
          checked={false}
          disabled
        />
        <Checkbox
          contrastTo="lightBackground"
          id="exampleDisabled4"
          mb="2"
          checked
          disabled
        />
      </Box>
    </Box>
  </>
);
