import React from 'react';
import { H1, BodyM, CaptionXS, Button } from '@otovo/rainbow';

import Box from '../../components/Box';

const LandingPage = () => (
  <Box>
    <CaptionXS>Design System</CaptionXS>
    <H1 mb="6">Photos</H1>

    <BodyM mb="6" maxWidth="measure">
      Need a picture of a solar installation? Our staff? Our office? Some stock
      photos? No problem, all of the above is available on Brandmaster.
    </BodyM>

    <Button
      as="a"
      href="https://otovo.brandmaster.com/brandcenter/en/otovobh/"
      variant="blue">
      See photos on Brandmaster
    </Button>
  </Box>
);

export default LandingPage;
