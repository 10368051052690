import React from 'react';
import Box from '../../../components/Box';

export const source = `
import { Box } from '@otovo/rainbow';

<Box bg="green_5" borderRadius="2" p="3" color="green_90">
  <strong>Hurray!</strong> It totally works!
</Box>
`;

export const render = (
  <Box bg="green_5" borderRadius="2" p="3" color="green_90">
    <strong>Hurray!</strong> It totally works!
  </Box>
);
