import React from 'react';
import { H1, H3, H6, BodyM, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';
import Code from '../../components/Code';
import Example from '../../components/Example/Example';
import HR from '../../components/HR';
import Quote from '../../components/Quote';

import TableBreakpoint from './TableBreakpoints';
import breakpoints from './breakpoints.svg';
import multiColumnAnswer from './multiColumnAnswer.png';
import multiColumnQuestion from './multiColumnQuestion.png';

import * as exampleEmotion from './examples/exampleEmotion';
import * as exampleStyledSystem from './examples/exampleStyledSystem';
import * as exampleStyledSystemGeneratedCSS from './examples/exampleStyledSystemGeneratedCSS';

const BreakpointsPage = () => (
  <>
    <CaptionXS as="h1">Breakpoints</CaptionXS>
    <H1 as="h2" mb="3">
      Theme values
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Rainbow has a total of nine breakpoints. Three small, three medium and
      three large. The exact breakpoints are shown in the table below.
    </BodyM>
    <TableBreakpoint />
    <BodyM mb="4" maxWidth="measure">
      Almost all breakpoints are <strong>10em / 160px</strong> apart. The
      exception to the rule is <Code>s2</Code>, which is defined as{' '}
      <strong>23em</strong>. This definition allows us to target big phones like
      iPhone 11 using <Code>s2</Code>, and small phones like iPhone SE using{' '}
      <Code>s</Code>.
    </BodyM>
    <BodyM mb="6" maxWidth="measure">
      All breakpoints should be thought of as <strong>"X and up"</strong> since
      none of them have an upper bounds. The illustration shows the range of
      each breakpoint.
    </BodyM>
    <Box maxWidth="measure" mb="8">
      <img src={breakpoints} alt="" />
    </Box>

    <HR />
    <CaptionXS>Breakpoints</CaptionXS>
    <H1 as="h2" mb="3">
      Usage
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Given the following...
    </BodyM>
    <Example {...exampleStyledSystem} />
    <BodyM mb="6" maxWidth="measure">
      ...the following CSS will be generated:
    </BodyM>
    <Example {...exampleStyledSystemGeneratedCSS} language="css" />
    <BodyM mb="6" maxWidth="measure">
      <strong>Note:</strong> You don't have to use all the breakpoints, only the
      ones you need.
    </BodyM>

    <H3>Emotion</H3>
    <BodyM mb="6" maxWidth="measure">
      When creating components using Emotion, you can access the breakpoints
      using <Code>mediaQuery()</Code>.
    </BodyM>
    <Example {...exampleEmotion} />

    <HR />
    <CaptionXS>Breakpoints</CaptionXS>
    <H1 as="h2" mb="3">
      Design philosophy
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Why does Rainbow define so many breakpoints? At first glance, nine
      breakpoints might seem a bit excessive. Most CSS frameworks out there
      provide far fewer. Bootstrap and Tailwind CSS has five. Tachyons and
      Foundation has three. What gives?
    </BodyM>

    <H3 mb="1">Common problems with few breakpoints</H3>
    <H6>Medium screen sizes</H6>
    <BodyM mb="4" maxWidth="measure">
      Designers usually create mockups in two sizes: Mobile and desktop.
      Developers often have to make tweaks to make the transition from one to
      the other go smoothly. The ideal time to make a tweak is rarely aligned
      with the predefined breakpoints. Breaking the design at suboptimal
      locations usually makes the page feel too cramped, unbalanced or simply
      kinda broken.
    </BodyM>

    <H6>Small vs large phones</H6>
    <BodyM mb="4" maxWidth="measure">
      Otovo has committed to support screens as small as iPhone SE. However,
      doing so sometimes means making compromises that leads to a weaker UX for
      people with bigger phones. In other words: To support a small minority, we
      often have to make the experience worse for the majority.
    </BodyM>

    <H6>Multi-column layouts</H6>
    <BodyM mb="6" maxWidth="measure">
      The more columns a design has, the more breakpoints you'll need. Also, the
      number of columns is often higher than you'd think. For example: How many
      columns of content does this design have?
    </BodyM>

    <Box boxShadow="0" display="flex" mb="8">
      <img
        src={multiColumnQuestion}
        alt="A layout with a sidebar, and a main area with two cards containing three columns each"
      />
    </Box>

    <BodyM mb="6" maxWidth="measure">
      If you answered <strong>7</strong> you're totally right!
    </BodyM>

    <Box boxShadow="0" display="flex" mb="8">
      <img src={multiColumnAnswer} alt="7 columns!" />
    </Box>

    <BodyM mb="6" maxWidth="measure">
      Implementing designs like the one above is infeasible when the amount of
      breakpoints is very limited.
    </BodyM>

    <H3 mb="1">Defining breakpoints on the fly</H3>
    <BodyM mb="4" maxWidth="measure">
      Now that we've covered some of the issues that appear when the number of
      breakpoints are limited, let's pose a different question instead: Why
      should we define breakpoints up front in the first place?
    </BodyM>
    <BodyM mb="6" maxWidth="measure">
      Ideally, content should determine the breakpoints. Stephen Hay proposes
      the following policy:
    </BodyM>
    <Quote author="Stephen Hay">
      Start with the small screen first, then expand until it looks like shit.
      Time for a breakpoint!
    </Quote>
    <BodyM mb="4" maxWidth="measure">
      Since breakpoints can be added exactly where they're needed, it becomes
      easier to make websites look good on all screen sizes.
    </BodyM>
    <BodyM mb="4" maxWidth="measure">
      Despite this, opting for this approach feels risky. Few large projects
      define breakpoints on the fly, which makes the strategy's pitfalls
      unknown. The code will probably be a bit messier and harder to parse than
      with predefined breakpoints. Using a naming scheme such as "small, medium,
      large" will probably make it easier to get an intuitive sense of size.
      With few breakpoints, you can synchronize breakpoints between components
      on the same page without having to think too much.
    </BodyM>
    <BodyM mb="4" maxWidth="measure">
      Rainbow's nine breakpoint is a compromise between the two approaches
      discussed above. It provides a decent amount of control without going "all
      out".
    </BodyM>
  </>
);

export default BreakpointsPage;
