import React from 'react';

import { Checkbox } from '@otovo/rainbow';

export const source = `
<Checkbox id="exampleSize1" size="4" />
<Checkbox id="exampleSize2" size="26px" />
<Checkbox id="exampleSize3" />
`;

export const render = (
  <>
    <Checkbox id="exampleSize1" checked size="4" mb="2" />
    <Checkbox id="exampleSize2" checked size="26px" mb="2" />
    <Checkbox id="exampleSize3" checked />
  </>
);
