import React, { useState } from 'react';

import { Input, BodyS } from '@otovo/rainbow';

export const source = `
const InputDemo = () => {
  const [text, setText] = useState('Sunny days');

  return (
    <>
      <Input value={text} onChange={e => setText(e.target.value)} />
      <BodyS mt="3" ml="1">
        <strong>Your text:</strong> {text}
      </BodyS>
    </>
  );
};
`;

const InputDemo = () => {
  const [text, setText] = useState('Sunny days');

  return (
    <>
      <Input value={text} onChange={e => setText(e.target.value)} />
      <BodyS mt="3" ml="1">
        <strong>Your text:</strong> {text}
      </BodyS>
    </>
  );
};

export const render = <InputDemo />;
