import React from 'react';
import styled from '@emotion/styled';
import { get } from '@otovo/rainbow';

export const source = `
import { get } from '@otovo/rainbow';

const CircleImg = styled.img\`
  width: \${get('sizes.10')};
  box-shadow: \${get('shadows.1')};
  border-radius: \${get('radii.circle')};
\`;

<CircleImg src="https://user-images.githubusercontent.com/3471625/59930951-2f136d00-9444-11e9-991f-ef8671648e9b.png" />
`;

const CircleImg = styled.img`
  width: ${get('sizes.10')};
  box-shadow: ${get('shadows.1')};
  border-radius: ${get('radii.circle')};
`;

export const render = (
  <CircleImg src="https://user-images.githubusercontent.com/3471625/59930951-2f136d00-9444-11e9-991f-ef8671648e9b.png" />
);
