import React, { useState } from 'react';

import { StackedButton } from '@otovo/rainbow';

export const source = `
import { StackedButton } from '@otovo/rainbow';

const { PrimaryText, SecondaryText } = StackedButton;
const [selectedPanel, setSelectedPanel] = useState('standard');
const panelTypes = [
  { type: 'standard', primary: 'Standard', secondary: '280 W, blue' },
  { type: 'premium', primary: 'Premium', secondary: '320 W, black' },
  { type: 'performance', primary: 'Performance', secondary: '360 W, black' },
];

return (
  <>
    {panelTypes.map(panel => (
      <StackedButton
        selected={selectedPanel === panel.type}
        onClick={() => {
          setSelectedPanel(panel.type);
        }}
        key={panel.type}>
        <PrimaryText>{panel.primary}</PrimaryText>
        <SecondaryText>{panel.secondary}</SecondaryText>
      </StackedButton>
    ))}
  </>
);
`;

const InteractiveStackedButtonDemo = () => {
  const { PrimaryText, SecondaryText } = StackedButton;
  const [selectedPanel, setSelectedPanel] = useState('standard');
  const panelTypes = [
    { type: 'standard', primary: 'Standard', secondary: '280 W, blue' },
    { type: 'premium', primary: 'Premium', secondary: '320 W, black' },
    { type: 'performance', primary: 'Performance', secondary: '360 W, black' },
  ];

  return (
    <>
      {panelTypes.map(panel => (
        <StackedButton
          selected={selectedPanel === panel.type}
          onClick={() => {
            setSelectedPanel(panel.type);
          }}
          key={panel.type}>
          <PrimaryText>{panel.primary}</PrimaryText>
          <SecondaryText>{panel.secondary}</SecondaryText>
        </StackedButton>
      ))}
    </>
  );
};

export const render = <InteractiveStackedButtonDemo />;
