import React from 'react';

import Box from './Box';
import InfoCircle from './InfoCircle';

const VARIANT_INFO = 'info';
const VARIANT_SUCESS = 'success';
const VARIANT_WARNING = 'warning';
const VARIANT_ERROR = 'error';

const getBackgroundColor = variant => {
  switch (variant) {
    case VARIANT_SUCESS:
      return 'green_1';
    case VARIANT_WARNING:
      return 'peach_5';
    case VARIANT_ERROR:
      return 'red_1';
    case VARIANT_INFO:
    default:
      return 'grey_1';
  }
};
const getIconBackgroundColor = variant => {
  switch (variant) {
    case VARIANT_SUCESS:
      return 'green_20';
    case VARIANT_WARNING:
      return 'peach_20';
    case VARIANT_ERROR:
      return 'red_20';
    case VARIANT_INFO:
    default:
      return 'blue_20';
  }
};

const getIconColor = variant => {
  switch (variant) {
    case VARIANT_SUCESS:
      return 'green_100';
    case VARIANT_WARNING:
      return 'peach_100';
    case VARIANT_ERROR:
      return 'red_100';
    case VARIANT_INFO:
    default:
      return 'blue_100';
  }
};

const Alert = ({ children, variant, ...rest }) => {
  const bg = getBackgroundColor(variant);
  const iconColor = getIconColor(variant);
  const iconBg = getIconBackgroundColor(variant);

  return (
    <Box
      maxWidth="measure"
      p="4"
      borderRadius="2"
      bg={bg}
      // color={color}
      display="flex"
      justifyContent="left"
      {...rest}>
      <InfoCircle bg={iconBg} color={iconColor} />
      <Box pl="3">{children}</Box>
    </Box>
  );
};

export default Alert;
