import React from 'react';

import { Input, Label, LabelDescription } from '@otovo/rainbow';

export const source = `
import { LabelDescription, Input, Label } from '@otovo/rainbow';

<Label htmlFor="password">Password</Label>
<LabelDescription id="password-description" mb="2">
  Must have at least 6 characters
</LabelDescription>
<Input id="ssn" aria-describedby="password-description" />
`;

export const render = (
  <>
    <Label htmlFor="password">Password</Label>
    <LabelDescription id="password-description" mb="2">
      Must have at least 6 characters
    </LabelDescription>
    <Input id="password" aria-describedby="password-description" />
  </>
);
