import React from 'react';
import { Button } from '@otovo/rainbow';

import IconArrowRight from './IconArrowRight';
import IconGlobe from './IconGlobe';
import IconLayers from './IconLayers';

export const source = `
<Button
  layout="space-between"  
  icon={<RightIcon />}>
  Left
</Button>

<Button
  layout="center"  
  icon={<LayersIcon />}>
  More
</Button>

<Button
  layout="left"  
  icon={<GlobeIcon />}>
  Browse
</Button>
`;

export const render = (
  <>
    <Button
      display="flex"
      mb="2"
      icon={<IconArrowRight />}
      layout="space-between">
      Next
    </Button>

    <Button display="flex" mb="2" icon={<IconLayers />} layout="center">
      More
    </Button>

    <Button display="flex" mb="2" icon={<IconGlobe />} layout="left">
      Browse
    </Button>
  </>
);
