import React, { useState } from 'react';
import { Button } from '@otovo/rainbow';

export const source = `
const [loading, setLoading] = useState(false);

return (
  <Button
    loading={loading}
    onClick={() => {
      setLoading(!loading);
    }}>
    Click me to spin!
  </Button>
);
`;

const LoadingDemo = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      loading={loading}
      onClick={() => {
        setLoading(!loading);
      }}>
      Click me to spin!
    </Button>
  );
};

export const render = <LoadingDemo />;
