import React from 'react';
import { Button } from '@otovo/rainbow';

export const source = `
<Button
  as="a"
  href="https://www.youtube.com/watch?v=lXMskKTw3Bc"
  variant="blue">
  Undercover link
</Button>
`;

export const render = (
  <Button
    as="a"
    href="https://www.youtube.com/watch?v=lXMskKTw3Bc"
    variant="blue">
    Undercover link
  </Button>
);
