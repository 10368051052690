import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Link } from 'react-navi';
import { compose } from 'styled-system';
import { get, H5, color, layout, space } from '@otovo/rainbow';

import logo from '../../img/otovo.svg';

import SidebarLink from './SidebarLink';

const SidebarWrap = styled.aside`
  position: fixed;
  z-index: 100;
  background-color: white;
  max-height: 100vh;
  overflow: scroll;
  padding: ${get('space.6')};
  box-shadow: ${get('shadows.0')};
  transition: all 0.5s ease-out;
  ${layout}
`;

const OtovoLogo = styled.img`
  ${compose(color, space, layout)}
`;

const Heading = ({ children }) => (
  <H5 mb="1" mt="6">
    {children}
  </H5>
);

const Sidebar = ({ display, width, theme }) => {
  return (
    <SidebarWrap display={display} width={width}>
      <Link href={`/`}>
        <OtovoLogo src={logo} width="80%" />
      </Link>

      <Heading>Core</Heading>
      <SidebarLink href="/core/colors">Colors</SidebarLink>
      <SidebarLink href="/core/typography">Typography</SidebarLink>
      <SidebarLink href="/core/logo">Logo</SidebarLink>
      <SidebarLink href="/core/illustrations">Illustrations</SidebarLink>
      <SidebarLink href="/core/photos">Photos</SidebarLink>
      <SidebarLink href="/core/content-style-guide">
        Content Style Guide
      </SidebarLink>

      <Heading>Atoms</Heading>
      <SidebarLink href="/atoms/accessing-the-theme">
        Accessing the theme
      </SidebarLink>
      <SidebarLink href="/atoms/breakpoints">Breakpoints</SidebarLink>
      <SidebarLink href="/atoms/sizes">Size &amp; space</SidebarLink>
      <SidebarLink href="/atoms/fontsizes">Font sizes</SidebarLink>

      <Heading>Components</Heading>
      <SidebarLink href="/components/button">Button</SidebarLink>
      <SidebarLink href="/components/stacked-buttons">
        Stacked buttons
      </SidebarLink>
      <SidebarLink href="/components/link">Link</SidebarLink>
      <SidebarLink href="/components/input">Input</SidebarLink>
      <SidebarLink href="/components/inputfield">Input field</SidebarLink>
      <SidebarLink href="/components/form-helpers">Form helpers</SidebarLink>
      <SidebarLink href="/components/toggle">Toggle</SidebarLink>
      <SidebarLink href="/components/togglefield">Toggle field</SidebarLink>
      <SidebarLink href="/components/checkbox">Checkbox</SidebarLink>
      <SidebarLink href="/components/checkboxfield">Checkbox field</SidebarLink>
      <SidebarLink href="/components/gradient-text">Gradient text</SidebarLink>
      <SidebarLink href="/components/badge">Badge</SidebarLink>
    </SidebarWrap>
  );
};

export default withTheme(Sidebar);
