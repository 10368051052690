import React from 'react';
import { H1, H3, BodyM, CaptionXS, Link } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';

import * as exampleBasic from './examples/exampleBasic';
import * as exampleInteractive from './examples/exampleInteractive';
import * as examplePlaceholder from './examples/examplePlaceholder';
import * as exampleDisabled from './examples/exampleDisabled';
import * as exampleRequired from './examples/exampleRequired';
import * as exampleType from './examples/exampleType';
import * as exampleWidth from './examples/exampleWidth';
import * as exampleIcon from './examples/exampleIcon';
import * as exampleContrastTo from './examples/exampleContrastTo';
import * as exampleValidationState from './examples/exampleValidationState';

const ButtonsPage = () => (
  <Box>
    <CaptionXS as="h1">Input</CaptionXS>
    <H1 as="h2" mb="3">
      Basic usage
    </H1>
    <BodyM mb="6" maxWidth="measure">
      The API for <Code>Input</Code> stays as close to the regular input API as
      possible. This means that attributes such as <Code>name</Code>,{' '}
      <Code>value</Code>, <Code>disabled</Code>, <Code>placeholder</Code>,{' '}
      <Code>type</Code> are supported.
    </BodyM>

    <Example {...exampleBasic} />

    <H3 mb="2">Placeholder</H3>
    <Example {...examplePlaceholder} />

    <H3 mb="2">Disabled</H3>
    <Example {...exampleDisabled} />

    <H3 mb="2">Required</H3>
    <BodyM mb="6" maxWidth="measure">
      <strong>Tip:</strong> Instead of using <Code>required</Code>, consider
      adding custom validation by using the <Code>error</Code> prop instead.
      This ensures a consistent design across browsers.
    </BodyM>
    <Example {...exampleRequired} />

    <H3 mb="2">Type</H3>
    <BodyM mb="6">
      Allowed values are <Code>text, password, tel, email</Code>.<br />
      All{' '}
      <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types">
        other types
      </Link>{' '}
      will throw an error.
    </BodyM>
    <Example {...exampleType} />

    <HR />

    <CaptionXS>Input</CaptionXS>
    <H1 as="h2" mb="3">
      Making it interactive
    </H1>
    <BodyM mb="6" maxWidth="measure">
      <Code>Input</Code> works the same way as regular{' '}
      <Link href="https://reactjs.org/docs/forms.html#controlled-components">
        controlled components
      </Link>
      . Make it interactive by adding the <Code>value</Code> and{' '}
      <Code>onChange</Code> props.
    </BodyM>
    <Example {...exampleInteractive} />

    <HR />

    <CaptionXS>Input</CaptionXS>
    <H1 as="h2" mb="3">
      Width
    </H1>
    <BodyM mb="4" maxWidth="measure">
      The default width is 256px. You can change the width using the{' '}
      <Code>width</Code> prop.
    </BodyM>
    <BodyM mb="6" maxWidth="measure">
      <strong>Usability tip!</strong> It's considered best practice to set an
      inputs width based on its content. For example: If you only accept a two
      digit number, the width of the input should reflect that.
    </BodyM>
    <Example {...exampleWidth} />

    <HR />

    <CaptionXS>Input</CaptionXS>
    <H1 as="h2" mb="3">
      Indicating errors
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Use <Code>validationState="error"</Code> to indicate that the input is not
      properly filled out. Doing so will mark the input with a red line and add{' '}
      <Code>aria-invalid="true"</Code>.
    </BodyM>
    <Example {...exampleValidationState} />

    <HR />

    <CaptionXS>Input</CaptionXS>
    <H1 as="h2" mb="3">
      ContrastTo
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>contrastTo</Code> prop to ensure good contrast against the
      background. Two values are accepted: <Code>lightBackground</Code>{' '}
      (default) and <Code>darkBackground</Code>.
    </BodyM>
    <Example {...exampleContrastTo} />

    <HR />

    <CaptionXS>Input</CaptionXS>
    <H1 as="h2" mb="3">
      Icon
    </H1>
    <BodyM mb="6" maxWidth="measure">
      To add an icon, use the <Code>icon</Code> prop. The supplied icon must be
      an SVG with height and width set to <strong>1em</strong>.
    </BodyM>
    <Example {...exampleIcon} />
  </Box>
);

export default ButtonsPage;
