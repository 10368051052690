import React, { useState } from 'react';

import { Checkbox, BodyS } from '@otovo/rainbow';

export const source = `
const [checked, setChecked] = useState(true);

return (
  <>
    <Checkbox
      checked={checked}
      onChange={e => setChecked(e.target.checked)}
      id="exampleInteractive"
    />
    <BodyS mt="3">
      The checkbox is <strong>{checked ? 'checked' : 'unchecked'}</strong>
    </BodyS>
  </>
);
`;

const InputDemo = () => {
  const [checked, setChecked] = useState(true);

  return (
    <>
      <Checkbox
        checked={checked}
        onChange={e => setChecked(e.target.checked)}
        id="exampleInteractive"
      />
      <BodyS mt="3">
        The checkbox is <strong>{checked ? 'checked' : 'unchecked'}</strong>
      </BodyS>
    </>
  );
};

export const render = <InputDemo />;
