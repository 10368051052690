import React from 'react';
import styled from '@emotion/styled';
import { mediaQuery } from '@otovo/rainbow';

export const source = `
import styled from '@emotion/styled';
import { get, mediaQuery } from '@otovo/rainbow';

const ColorBorderBox = styled.div\`
  background-color: \${get('colors.blue_50')};
  height: \${get('sizes.10')};
  width: \${get('sizes.10')};

  \${mediaQuery('m3')} {
    background-color: \${get('colors.peach_50')};
  }
\`;
`;

const ColorBorderBox = styled.div`
  background-color: ${props => props.theme.colors.blue_50};
  height: ${props => props.theme.sizes[10]};
  width: ${props => props.theme.sizes[10]};

  ${mediaQuery('m3')} {
    background-color: ${props => props.theme.colors.peach_50};
  }
`;

export const render = <ColorBorderBox />;
