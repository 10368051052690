import React from 'react';

import { Badge } from '@otovo/rainbow';

export const source = `
import { Badge } from '@otovo/rainbow';

<Badge>New</Badge>
`;

export const render = <Badge>New</Badge>;
