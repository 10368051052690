import React from 'react';
import { H1, BodyM, CaptionXS, Button } from '@otovo/rainbow';

import Box from '../../components/Box';

const LandingPage = () => (
  <Box>
    <CaptionXS>Design System</CaptionXS>
    <H1 mb="6">Logos</H1>

    <BodyM mb="6" maxWidth="measure">
      The Otovo logo is available in several versions and formats. All of them
      are available on Brandmaster.
    </BodyM>

    <Button
      as="a"
      href="https://otovo.brandmaster.com/brandcenter/en/otovobh/"
      variant="blue">
      See logos on Brandmaster
    </Button>
  </Box>
);

export default LandingPage;
