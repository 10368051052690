import React from 'react';

import Table from '../../components/Table';

const TableBreakpoints = () => (
  <Table firstColumn="bold">
    <thead>
      <tr>
        <th></th>
        <th>s</th>
        <th>s2</th>
        <th>s3</th>
        <th>m</th>
        <th>m2</th>
        <th>m3</th>
        <th>l</th>
        <th>l2</th>
        <th>l3</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>em</td>
        <td>-</td>
        <td>23</td>
        <td>30</td>
        <td>40</td>
        <td>50</td>
        <td>60</td>
        <td>70</td>
        <td>80</td>
        <td>90</td>
      </tr>
      <tr>
        <td>px</td>
        <td>-</td>
        <td>368</td>
        <td>480</td>
        <td>640</td>
        <td>800</td>
        <td>960</td>
        <td>1120</td>
        <td>1280</td>
        <td>1440</td>
      </tr>
    </tbody>
  </Table>
);

export default TableBreakpoints;
