import React from 'react';

import { ToggleField } from '@otovo/rainbow';

export const source = `
<ToggleField
  layout="right"
  id="…"
  label="…"
/>
`;

export const render = (
  <>
    <ToggleField
      layout="right"
      id="exampleLayoutRight1"
      label="I want a solar loan"
      mb="4"
    />
    <ToggleField
      layout="right"
      id="exampleLayoutRight2"
      label="See price after subsidies"
      mb="4"
    />
    <ToggleField
      layout="right"
      id="exampleLayoutRight3"
      label="I've read the terms and conditions"
      mb="4"
    />
  </>
);
