import React from 'react';
import { H1, H3, BodyM, CaptionXS, Link } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';

import * as exampleBasic from './examples/exampleBasic';
import * as exampleErrorMessage from './examples/exampleErrorMessage';
import * as exampleLabelDescription from './examples/exampleLabelDescription';
import * as exampleValidationState from './examples/exampleValidationState';
import * as exampleContrastTo from './examples/exampleContrastTo';

const InputFieldPage = () => (
  <Box>
    <CaptionXS as="h1">Input field</CaptionXS>
    <H1 as="h2" mb="3">
      InputField vs. Input
    </H1>
    <BodyM mb="3" maxWidth="measure">
      <Code>InputField</Code> combines <Code>Input</Code>,<Code>Label</Code>,{' '}
      <Code>LabelDescription</Code> and <Code>ErrorMessage</Code>. Using it has
      a few advantages:
    </BodyM>
    <BodyM as="ul" ml="5" mb="6" maxWidth="measure">
      <li>
        <strong>Accessibility</strong> attributes are automatically added
      </li>
      <li>
        <strong>Consistent look</strong> with a standardized form design
      </li>
      <li>
        <strong>Increased code readability</strong> by managing some details for
        you
      </li>
    </BodyM>

    <HR />

    <CaptionXS as="h1">Input field</CaptionXS>
    <H1 as="h2" mb="3">
      Usage
    </H1>
    <BodyM mb="3" maxWidth="measure">
      There are two required props: <Code>id</Code> and <Code>label</Code>.
    </BodyM>
    <Example {...exampleBasic} />

    <H3 mb="1">Same props as Input</H3>
    <BodyM mb="6" maxWidth="measure">
      <Code>InputField</Code> has the same capabilities as <Code>Input</Code>.
      Props such as <strong>name</strong>, <strong>placeholder</strong>,{' '}
      <strong>onChange</strong>, <strong>value</strong>,{' '}
      <strong>disabled</strong>, <strong>type</strong> and <strong>icon</strong>{' '}
      are all supported. Instead of repeating them here, check out the{' '}
      <Link href="/components/input">Input documentation</Link>.
    </BodyM>

    <H3 mb="1">Label description</H3>
    <BodyM mb="3" maxWidth="measure">
      Use <Code>labelDescription</Code> to add additional information about the
      input.
    </BodyM>
    <Example {...exampleLabelDescription} />

    <H3 mb="1">Indicating errors</H3>
    <BodyM mb="3" maxWidth="measure">
      To highlight an improperly filled input, use the prop{' '}
      <Code>validationState="error"</Code>.
    </BodyM>
    <Example {...exampleValidationState} />

    <BodyM mb="3" maxWidth="measure">
      To add text describing the error, use the prop <Code>errorMessage</Code>.
      The message is hidden until <Code>validationState="error"</Code> is set.
    </BodyM>
    <Example {...exampleErrorMessage} />

    <H3 mb="1">ContrastTo</H3>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>contrastTo</Code> prop to ensure good contrast against the
      background. Two values are accepted: <Code>lightBackground</Code>{' '}
      (default) and <Code>darkBackground</Code>.
    </BodyM>
    <Example {...exampleContrastTo} />
  </Box>
);

export default InputFieldPage;
