import React from 'react';
import { H1, BodyM, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';

import * as exampleBasic from './examples/exampleBasic';
import * as exampleChooseGradient from './examples/exampleChooseGradient';
import * as exampleOtherTypographyComponents from './examples/exampleOtherTypographyComponents';

const GradientTextPage = () => (
  <Box>
    <CaptionXS as="h1">Gradient text</CaptionXS>
    <H1 as="h2" mb="3">
      Basic usage
    </H1>
    <BodyM mb="6" maxWidth="measure">
      <Code>GradientText</Code> can be used to give text some flair. 💅
    </BodyM>
    <Example {...exampleBasic} />
    <HR />

    <CaptionXS>Stacked buttons</CaptionXS>
    <H1 as="h2" mb="3">
      Choosing the gradient
    </H1>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>backgroundImage</Code> prop to choose gradient. The default
      is `gradientSun`, but you can use any of the gradients in Rainbows
      gradient scale, or define your own on the fly.
    </BodyM>
    <Example {...exampleChooseGradient} />
    <HR />

    <CaptionXS>Stacked buttons</CaptionXS>
    <H1 as="h2" mb="3">
      Usage with H1, BodyM, etc
    </H1>
    <BodyM mb="6" maxWidth="measure">
      <Code>GradientText</Code> is rendered as a simple span. Just like{' '}
      <strong>strong</strong> and <strong>em</strong>, it doesn't have any
      default font-size, font-weight, line-height, etc. This makes it suited to
      be used in combination with other Rainbow typography components.
    </BodyM>
    <Example {...exampleOtherTypographyComponents} />
  </Box>
);

export default GradientTextPage;
