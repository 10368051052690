import styled from '@emotion/styled';
import { compose } from 'styled-system';
import { color, space, border } from '@otovo/rainbow';

const HR = styled.hr`
  ${compose(
    color,
    space,
    border,
  )}
`;

HR.defaultProps = {
  border: 0,
  borderTop: 2,
  borderColor: 'lilac_10',
  mb: '7',
};

export default HR;
