import React from 'react';
import { H1, H3, H5, BodyM, CaptionXS, Link } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';

import * as exampleBasic from './examples/exampleBasic';
import * as exampleLabelDescription from './examples/exampleLabelDescription';
import * as exampleLayoutRight from './examples/exampleLayoutRight';
import * as exampleLayoutLeft from './examples/exampleLayoutLeft';
import * as exampleLayoutVertical from './examples/exampleLayoutVertical';
import * as exampleContrastTo from './examples/exampleContrastTo';

const CheckboxFieldPage = () => (
  <Box>
    <CaptionXS as="h1">Checkbox Field</CaptionXS>
    <H1 as="h2" mb="3">
      CheckboxField vs. Checkbox
    </H1>
    <BodyM mb="3" maxWidth="measure">
      <Code>CheckboxField</Code> combines <Code>Checkbox</Code>,{' '}
      <Code>Label</Code> and <Code>LabelDescription</Code>. Using it has a few
      advantages:
    </BodyM>
    <BodyM as="ul" ml="5" mb="6" maxWidth="measure">
      <li>
        <strong>Accessibility</strong> attributes are automatically added
      </li>
      <li>
        <strong>Consistent look</strong> with a standardized form design
      </li>
      <li>
        <strong>Increased code readability</strong> by managing some details for
        you
      </li>
    </BodyM>

    <HR />

    <CaptionXS as="h1">Checkbox field</CaptionXS>
    <H1 as="h2" mb="3">
      Usage
    </H1>
    <BodyM mb="3" maxWidth="measure">
      There are two required props: <Code>id</Code> and <Code>label</Code>.
    </BodyM>
    <Example {...exampleBasic} />

    <H3 mb="1">Same props as Checkbox</H3>
    <BodyM mb="6" maxWidth="measure">
      <Code>CheckboxField</Code> has the same capabilities as{' '}
      <Code>Checkbox</Code>. Props such as <strong>checked</strong>,{' '}
      <strong>onChange</strong>, <strong>name</strong>,{' '}
      <strong>disabled</strong>, <strong>size</strong> and{' '}
      <strong>value</strong> are all supported. Instead of repeating them here,
      check out the{' '}
      <Link href="/components/checkbox">Checkbox documentation</Link>.
    </BodyM>

    <H3 mb="1">Label description</H3>
    <BodyM mb="3" maxWidth="measure">
      Use <Code>labelDescription</Code> to add additional information about the
      input.
    </BodyM>
    <Example {...exampleLabelDescription} />

    <H3 mb="1">Layout</H3>
    <BodyM mb="3" maxWidth="measure">
      To change the position of the Checkbox, use{' '}
      <Code>layout="right|left|vertical"</Code>. The default is{' '}
      <Code>left</Code>.
    </BodyM>
    <BodyM mb="6" maxWidth="measure">
      <strong>NB!</strong> When using the <Code>right</Code> layout, the
      Checkbox and label might end up very far apart. Add a wrapper or{' '}
      <Code>maxWidth</Code> prop to limit its width.
    </BodyM>

    <H5 mb="2">Left</H5>
    <Example {...exampleLayoutLeft} />

    <H5 mb="2">Right </H5>
    <Example {...exampleLayoutRight} />

    <H5 mb="2">Vertical</H5>
    <Example {...exampleLayoutVertical} />

    <H3 mb="1">ContrastTo</H3>
    <BodyM mb="6" maxWidth="measure">
      Use the <Code>contrastTo</Code> prop to ensure good contrast against the
      background. Two values are accepted: <Code>lightBackground</Code>{' '}
      (default) and <Code>darkBackground</Code>.
    </BodyM>
    <Example {...exampleContrastTo} />
  </Box>
);

export default CheckboxFieldPage;
