import React from 'react';

import { Input } from '@otovo/rainbow';

export const source = `
<Input validationState="error" />
`;

export const render = (
  <>
    <Input validationState="error" />
  </>
);
