import React from 'react';
import { Button } from '@otovo/rainbow';

export const source = `
<Button
  loading
  disabled={false}
  onClick={() => {
    alert("Aaah! I'm clicked!");
  }}>
  Some text
</Button>
</>
`;

export const render = (
  <Button
    loading
    disabled={false}
    onClick={() => {
      alert("Aaah! I'm clicked!");
    }}>
    Some text
  </Button>
);
