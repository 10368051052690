import React from 'react';

import { StackedButton } from '@otovo/rainbow';

export const source = `
import { StackedButton } from '@otovo/rainbow';
const { PrimaryText, Dot } = StackedButton;

return (
  <>
    <StackedButton>
      <Dot color="red_50" />
      <PrimaryText>Surface 1</PrimaryText>
    </StackedButton>

    <StackedButton>
      <Dot color="green_50" />
      <PrimaryText>Surface 2</PrimaryText>
    </StackedButton>

    <StackedButton>
      <Dot color="blue_50" />
      <PrimaryText>Surface 3</PrimaryText>
    </StackedButton>
  </>
);
`;

const ExamplePrimaryText = () => {
  const { PrimaryText, Dot } = StackedButton;
  return (
    <>
      <StackedButton>
        <Dot color="red_50" />
        <PrimaryText>Surface 1</PrimaryText>
      </StackedButton>

      <StackedButton>
        <Dot color="green_50" />
        <PrimaryText>Surface 2</PrimaryText>
      </StackedButton>

      <StackedButton>
        <Dot color="blue_50" />
        <PrimaryText>Surface 3</PrimaryText>
      </StackedButton>
    </>
  );
};

export const render = <ExamplePrimaryText />;
