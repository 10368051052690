import React from 'react';

import { Toggle } from '@otovo/rainbow';

export const source = `
<Toggle id="exampleSize1" size="5" />
<Toggle id="exampleSize2" />
<Toggle id="exampleSize3" size="45px" />
`;

export const render = (
  <>
    <Toggle id="exampleSize1" size="5" mb="2" />
    <Toggle id="exampleSize2" mb="2" />
    <Toggle id="exampleSize3" size="45px" mb="2" />
  </>
);
