import styled from '@emotion/styled';
import { compose } from 'styled-system';
import { border, color, layout, space, typography } from '@otovo/rainbow';

const Code = styled.code`
  ${compose(
    color,
    layout,
    space,
    typography,
    border,
  )}
`;

Code.defaultProps = {
  fontFamily: 'monospace',
  color: 'peach_90',
  bg: 'peach_5',
  fontWeight: 'medium',
  letterSpacing: 's',
  px: 1,
  pb: '2px',
  mx: '2px',
  borderRadius: 2,
};

export default Code;
