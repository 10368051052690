import React from 'react';
import { Button } from '@otovo/rainbow';

export const source = `
import { Button } from '@otovo/rainbow';

<Button variant="blue">Blue</Button>
<Button variant="peach">Peach</Button>
`;

export const render = (
  <>
    <Button variant="blue" mb="3" mr="3">
      Blue
    </Button>
    <Button variant="peach">Peach</Button>
  </>
);
