export const source = `
import React from 'react';
import { Link as NaviLink } from 'react-navi';
import { Link as RainbowLink } from '@otovo/rainbow';

const Link = ({ as, href, children, ...rest }) => {
  const tag =
    !!as ||
    (href && href.startsWith('mailto:')) ||
    (href && href.startsWith('tel:'))
      ? as
      : NaviLink;

  return (
    <RainbowLink as={tag} href={href} {...rest}>
      {children}
    </RainbowLink>
  );
};

export default Link;
`;
