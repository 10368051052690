import React from 'react';
import { Box, Button } from '@otovo/rainbow';

import IconArrowRight from './IconArrowRight';

export const source = `
import { Box, Button } from '@otovo/rainbow';
import IconArrowRight from 'some/folder';

<Box maxWidth="12">
  <Button width="100%" icon={<IconArrowRight />}>
    Continue
  </Button>
</Box>
`;

export const render = (
  <Box maxWidth="12">
    <Button width="100%" icon={<IconArrowRight />}>
      Continue
    </Button>
  </Box>
);
