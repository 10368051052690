import React from 'react';

const ArrowRight = () => (
  <svg
    width="1em"
    height="1em"
    fill="currentColor"
    fillRule="evenodd"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M10.758.671l8.571 8.571a1.071 1.071 0 0 1 0 1.516l-8.571 8.571a1.071 1.071 0 1 1-1.516-1.515l6.671-6.672H1.071a1.071 1.071 0 1 1 0-2.142h14.985L9.242 2.185A1.071 1.071 0 0 1 10.758.671z" />
  </svg>
);

export default ArrowRight;
