import React from 'react';

import Table from '../../../components/Table';

const TableFontSizesExtras = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>_5a</th>
          <th>_6a</th>
          <th>_6b</th>
          <th>_7a</th>
          <th>_8a</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>px</td>
          <td>22</td>
          <td>26</td>
          <td>28</td>
          <td>34</td>
          <td>44</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableFontSizesExtras;
