import React from 'react';
import { Box, Checkbox } from '@otovo/rainbow';

export const source = `
import { Checkbox } from '@otovo/rainbow';

<Checkbox id="basic-checkbox" />
`;

export const render = (
  <Box display="flex" flexDirection="column" alignItems="flex-start">
    <Checkbox id="basic-checkbox" />
  </Box>
);
