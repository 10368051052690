import React, { useState } from 'react';
import styled from '@emotion/styled';
import * as clipboard from 'clipboard-polyfill';
import { get, mediaQuery, BodyS } from '@otovo/rainbow';

import Box from '../../components/Box';
import Tooltip from '../../components/Tooltip';
import copyIcon from '../../img/copy.svg';

import hexToAnything from './utils/hexToAnything';

const CopyIcon = styled.img`
  position: relative;
  top: 2px;
`;

const CopyButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  visibility: hidden;

  .color-palette-row:hover & {
    visibility: visible;
  }
`;

const RowHeading = styled(BodyS)`
  color: ${get('colors.text_heading')};
  font-weight: 500;
`;
const RowText = styled(BodyS)`
  color: ${get('colors.grey_50')};

  .color-palette-row:hover & {
    color: ${get('colors.text_heading')};
  }
`;

const RowWrap = styled(Box)`
  display: grid;
  grid-template-columns: ${get('sizes.9')} 1fr;

  ${mediaQuery('l')} {
    grid-template-columns: ${get('sizes.10')} 1fr;
  }

  ${mediaQuery('l2')} {
    grid-template-columns: ${get('sizes.11')} 1fr;
  }
`;

const ContentWrap = styled(Box)`
  display: grid;
  grid-template-columns: 1fr ${get('sizes.6')};
  grid-template-areas:
    'name        copyButton'
    'colorAsText copyButton';

  &:hover,
  .color-palette-row:hover & {
    background-color: ${get('colors.lilac_1')};
    color: ${get('colors.text_body')};
  }
`;

const Row = ({ color: hexColor, colorSpace, name }) => {
  const [copied, setCopied] = useState(false);

  const color = hexToAnything(hexColor, colorSpace);

  const handleClick = () => {
    clipboard.writeText(color);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <RowWrap className="color-palette-row">
      <Box bg={hexColor} />
      <ContentWrap py="4" px="5">
        <Box gridArea="name" pr="5" pb="1">
          <RowHeading>{name}</RowHeading>
        </Box>
        <Box gridArea="colorAsText">
          <RowText display="inline">{color}</RowText>
        </Box>
        <Box gridArea="copyButton" display="flex" justifyContent="flex-end">
          <Tooltip content="Copied!" arrow visible={copied} duration={100}>
            <CopyButton onClick={handleClick}>
              <CopyIcon src={copyIcon} alt="Copy to clipboard" />
            </CopyButton>
          </Tooltip>
        </Box>
      </ContentWrap>
    </RowWrap>
  );
};

const ColorPalette = ({ colors, colorSpace }) => {
  return (
    <Box boxShadow="0">
      {colors.map(([name, color]) => (
        <Row color={color} colorSpace={colorSpace} name={name} key={name} />
      ))}
    </Box>
  );
};

export default ColorPalette;
