import React from 'react';

import { Input, Label } from '@otovo/rainbow';

export const source = `
import { Label, Input } from '@otovo/rainbow';

<Label htmlFor="ssn" mb="1">
  Social security number
</Label>
<Input id="ssn" />
`;

export const render = (
  <>
    <Label htmlFor="ssn" mb="1">
      Social security number
    </Label>
    <Input id="ssn" />
  </>
);
