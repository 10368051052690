import React from 'react';

import { InputField } from '@otovo/rainbow';

export const source = `
<InputField
  id="labelValidationStateExample"
  label="Password"
  validationState="error"
/>
`;

export const render = (
  <InputField
    id="labelValidationStateExample"
    label="Password"
    validationState="error"
  />
);
