import React from 'react';

import { CheckboxField, Box } from '@otovo/rainbow';

export const source = `
<Box p="5" bg="darkLilac">
  <CheckboxField
    contrastTo="darkBackground"
    id="…"
    label="…"
    labelDescription="…"
  />
</Box>

<Box p="5" bg="lilac_5">
  <CheckboxField
    contrastTo="lightBackground"
    id="…"
    label="…"
    labelDescription="…"
  />
</Box>
`;

export const render = (
  <>
    <Box p="5" bg="darkLilac">
      <CheckboxField
        id="exampleContrastTo1"
        label="I want a solar loan"
        labelDescription="Why? Because solar is cool!"
        contrastTo="darkBackground"
      />
    </Box>
    <Box p="5" bg="lilac_5">
      <CheckboxField
        id="exampleContrastTo2"
        label="I want a solar loan"
        labelDescription="Why? Because solar is cool!"
        contrastTo="lightBackground"
      />
    </Box>
  </>
);
