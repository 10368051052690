import React from 'react';

import Table from '../../../components/Table';

const TableFontSizes = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>0</th>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
          <th>6</th>
          <th>7</th>
          <th>8</th>
          <th>9</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>px</td>
          <td>0</td>
          <td>12</td>
          <td>14</td>
          <td>16</td>
          <td>18</td>
          <td>20</td>
          <td>24</td>
          <td>30</td>
          <td>36</td>
          <td>46</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableFontSizes;
