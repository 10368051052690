import React from 'react';
import { Text } from '@otovo/rainbow';

const Quote = ({ children, author }) => {
  return (
    <>
      <Text
        as="blockquote"
        fontStyle="italic"
        maxWidth="measure"
        fontSize="2"
        ml="5"
        mb="2">
        “{children}”
      </Text>
      <Text ml="5" mb="6" fontWeight="semibold" maxWidth="measure" fontSize="2">
        — {author}
      </Text>
    </>
  );
};

export default Quote;
