import React from 'react';

import {
  Form,
  Button,
  Input,
  Label,
  LabelDescription,
  ErrorMessage,
} from '@otovo/rainbow';

export const source = `
<Form
  contrastTo="darkBackground"
  bg="darkLilac"
  p="5"
  onSubmit={...}>
  // Form content
</Form>
`;

export const render = (
  <Form
    contrastTo="darkBackground"
    bg="darkLilac"
    p="5"
    onSubmit={() => {
      alert('I feel sooo submitted right now');
    }}>
    <Label htmlFor="name2">Name</Label>
    <LabelDescription mb="2" id="name2-description">
      Your full name
    </LabelDescription>
    <Input id="name2" aria-describedby="name2-description" />

    <Label htmlFor="occupation2" mt="5">
      Occupation
    </Label>
    <LabelDescription mb="2" id="occupation2-description">
      As in "job", not as in "using military force to take territory"
    </LabelDescription>
    <Input
      id="occupation2"
      aria-describedby="occupation2-error occupation2-description"
      validationState="error"
    />
    <ErrorMessage id="occupation2-error" mt="2">
      Uh oh! Someones in trouble!
    </ErrorMessage>

    <Label htmlFor="framework2" mb="1" mt="5">
      Favorite framework
    </Label>
    <Input id="framework2" value="Rainbow" disabled />

    <Button variant="peach" type="submit" mt="5">
      Submit
    </Button>
  </Form>
);
