import React from 'react';
import { Link, Box } from '@otovo/rainbow';

export const source = `
<Box bg="lilac_5" p="4" mb="3">
  <Link href="#">Digital</Link>
</Box>
<Box bg="blue_90" p="4">
  <Link href="#" color="digitalite">
    Digitalite
  </Link>
</Box>
`;

export const render = (
  <>
    <Box display="flex" flexDirection="column">
      <Box bg="lilac_5" p="4" mb="3">
        <Link href="https://www.youtube.com/watch?v=9C_HReR_McQ">Digital</Link>
      </Box>
      <Box bg="blue_90" p="4">
        <Link
          href="https://www.youtube.com/watch?v=JQU6o4ooL5E"
          color="digitalite">
          Digitalite
        </Link>
      </Box>
    </Box>
  </>
);
