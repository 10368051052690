import React from 'react';

import { Box, Checkbox } from '@otovo/rainbow';

export const source = `
<Box width="50%" p="5" bg="darkLilac">
  <Checkbox contrastTo="darkBackground" id="exampleContrastTo1" checked={false} />
  <Checkbox contrastTo="darkBackground" id="exampleContrastTo2" checked />
</Box>

<Box width="50%" p="5" bg="lilac_5">
  <Checkbox contrastTo="lightBackground" id="exampleContrastTo3" checked={false} />
  <Checkbox contrastTo="lightBackground" id="exampleContrastTo4" checked />
</Box>
`;

export const render = (
  <>
    <Box display="flex" width="100%">
      <Box width="50%" p="5" bg="darkLilac">
        <Checkbox
          contrastTo="darkBackground"
          id="exampleContrastTo1"
          mb="2"
          checked={false}
        />
        <Checkbox
          contrastTo="darkBackground"
          id="exampleContrastTo2"
          mb="2"
          checked
        />
      </Box>
      <Box width="50%" p="5" bg="lilac_5">
        <Checkbox
          contrastTo="lightBackground"
          id="exampleContrastTo3"
          mb="2"
          checked={false}
        />
        <Checkbox
          contrastTo="lightBackground"
          id="exampleContrastTo4"
          mb="2"
          checked
        />
      </Box>
    </Box>
  </>
);
