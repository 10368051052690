import React from 'react';

import { Box, Input } from '@otovo/rainbow';

export const source = `
<Input width="10" />
<Input />
<Input width="100%" />
`;

export const render = (
  <Box display="grid" gridRowGap="3" justifyItems="start">
    <Input width="10" />
    <Input />
    <Input width="100%" />
  </Box>
);
