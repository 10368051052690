import React from 'react';

import { Box, Label } from '@otovo/rainbow';

export const source = `
<Box p="4" bg="darkLilac">
  <Label contrastTo="darkBackground">Social security number</Label>
</Box>
<Box p="4" bg="lilac_5">
  <Label>Social security number</Label>
</Box>
`;

export const render = (
  <>
    <Box p="4" bg="darkLilac">
      <Label contrastTo="darkBackground">Social security number</Label>
    </Box>
    <Box p="4" bg="lilac_5">
      <Label>Social security number</Label>
    </Box>
  </>
);
