import React from 'react';

import { Badge, BodyS, BodyM, BodyL } from '@otovo/rainbow';

export const source = `
<BodyS>
  <Badge>Small</Badge> I'm kinda smol
</BodyS>

<BodyM>
  <Badge>Medium</Badge> I'm extremely medium
</BodyM>

<BodyL>
  <Badge>Large</Badge> I'm pretty big!
</BodyL>

<BodyL>
  <Badge fontSize="7">I'M HUUUGE</Badge>
</BodyL>
`;

export const render = (
  <>
    <BodyS mb="3">
      <Badge>Small</Badge> I'm kinda smol
    </BodyS>
    <BodyM mb="3">
      <Badge>Medium</Badge> I'm extremely medium
    </BodyM>
    <BodyL mb="3">
      <Badge>Large</Badge> I'm pretty big!
    </BodyL>

    <BodyL mb="3">
      <Badge fontSize="7">I'M HUUUGE</Badge>
    </BodyL>
  </>
);
