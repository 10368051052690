import React, { useState } from 'react';

import { StackedButton } from '@otovo/rainbow';

export const source = `
const { PrimaryText } = StackedButton;

const [selectedBattery, setSelectedBattery] = useState(false);
const [selectedEVCharger, setSelectedEVCharger] = useState(false);

return (
  <>
    <StackedButton
      selected={selectedBattery}
      onClick={() => {
        setSelectedBattery(!selectedBattery);
      }}
      mb="4">
      <PrimaryText>Battery</PrimaryText>
    </StackedButton>

    <StackedButton
      selected={selectedEVCharger}
      onClick={() => {
        setSelectedEVCharger(!selectedEVCharger);
      }}
      mb="4">
      <PrimaryText>EV Charger</PrimaryText>
    </StackedButton>
  </>
);
`;

const InteractiveStackedButtonDemo = () => {
  const [selectedBattery, setSelectedBattery] = useState(false);
  const [selectedEVCharger, setSelectedEVCharger] = useState(false);
  const { PrimaryText } = StackedButton;

  return (
    <>
      <StackedButton
        selected={selectedBattery}
        onClick={() => {
          setSelectedBattery(!selectedBattery);
        }}
        mb="4">
        <PrimaryText>Battery</PrimaryText>
      </StackedButton>

      <StackedButton
        selected={selectedEVCharger}
        onClick={() => {
          setSelectedEVCharger(!selectedEVCharger);
        }}
        mb="4">
        <PrimaryText>EV Charger</PrimaryText>
      </StackedButton>
    </>
  );
};

export const render = <InteractiveStackedButtonDemo />;
