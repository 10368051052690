import React from 'react';

import { BodyL, GradientText, H1 } from '@otovo/rainbow';

export const source = `
<H1 mb="2">
  Some <GradientText>heading</GradientText>.
</H1>

<BodyL>
  Adds <GradientText>emphasis</GradientText> when used inline. Be wary of
  the contrast, though!
</BodyL>
`;

export const render = (
  <>
    <H1 as="p" mb="2">
      Some <GradientText>heading</GradientText>.
    </H1>

    <BodyL>
      Adds <GradientText>emphasis</GradientText> when used inline. Be wary of
      the contrast, though!
    </BodyL>
  </>
);
