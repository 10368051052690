import React from 'react';
import { Input } from '@otovo/rainbow';

import CheckmarkCircle from '../../../components/CheckmarkCircle';

export const source = `
<Input icon={<CheckmarkCircle />} />
`;

export const render = (
  <>
    <Input icon={<CheckmarkCircle bg="eGreen" color="white" />} />
  </>
);
