// Removes the color system part from color string
// Example: rgb(244,150,3) => 244, 150, 3
export default function stripColorSystemFromString(color) {
  const colorArr = color
    .replace('cmyk', '')
    .replace('rgb', '')
    .replace('hsl', '')
    .replace('(', '')
    .replace(')', '')
    .split(',');

  return colorArr.join(', ');
}
