import React from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { get, H1, H3, BodyM, BodyS, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Code from '../../components/Code';

import ColorCard from './ColorCard';
import GradientCard from './GradientCard';
import ColorPalette from './ColorPalette';
import ColorspaceButtons from './ColorspaceButtons';
import { HEX } from './constants';
import useStateWithLocalStorage from './utils/useStateWithLocalStorage';

import lilac1 from './img/lilac1.png';
import lilac2 from './img/lilac2.png';
import lilac3 from './img/lilac3.png';
import lilac4 from './img/lilac4.png';

const Img = styled.img`
  margin-bottom: ${get('space.5')};
`;

const ColorPageRow = ({ children, alignItems = 'start', gridGap = '6' }) => (
  <Box
    display="grid"
    gridTemplateColumns={{ s: '1fr', m: '1fr 1fr' }}
    gridGap={gridGap}
    mb="7"
    alignItems={alignItems}>
    {children}
  </Box>
);

const ColorsPage = ({ theme }) => {
  const [colorSpace, setColorSpace] = useStateWithLocalStorage(
    'colorSpace',
    HEX,
  );

  const { colors, gradients } = theme;

  const peachPalette = [
    ['peach_100', colors.peach_100],
    ['peach_90', colors.peach_90],
    ['peach_80', colors.peach_80],
    ['peach_75', colors.peach_75],
    ['peach_70', colors.peach_70],
    ['peach_60', colors.peach_60],
    ['peach_50', colors.peach_50],
    ['peach_40', colors.peach_40],
    ['peach_35', colors.peach_35],
    ['peach_30', colors.peach_30],
    ['peach_20', colors.peach_20],
    ['peach_10', colors.peach_10],
    ['peach_5', colors.peach_5],
    ['peach_1', colors.peach_1],
  ];

  const bluePalette = [
    ['blue_100', colors.blue_100],
    ['blue_90', colors.blue_90],
    ['blue_85', colors.blue_85],
    ['blue_80', colors.blue_80],
    ['blue_70', colors.blue_70],
    ['blue_60', colors.blue_60],
    ['blue_50', colors.blue_50],
    ['blue_40', colors.blue_40],
    ['blue_35', colors.blue_35],
    ['blue_30', colors.blue_30],
    ['blue_20', colors.blue_20],
    ['blue_10', colors.blue_10],
    ['blue_5', colors.blue_5],
    ['blue_1', colors.blue_1],
  ];

  const lilacPalette = [
    ['lilac_100', colors.lilac_100],
    ['lilac_90', colors.lilac_90],
    ['lilac_80', colors.lilac_80],
    ['lilac_70', colors.lilac_70],
    ['lilac_60', colors.lilac_60],
    ['lilac_50', colors.lilac_50],
    ['lilac_40', colors.lilac_40],
    ['lilac_30', colors.lilac_30],
    ['lilac_25', colors.lilac_25],
    ['lilac_20', colors.lilac_20],
    ['lilac_10', colors.lilac_10],
    ['lilac_5', colors.lilac_5],
    ['lilac_1', colors.lilac_1],
  ];

  const greyPalette = [
    ['grey_100', colors.grey_100],
    ['grey_90', colors.grey_90],
    ['grey_80', colors.grey_80],
    ['grey_70', colors.grey_70],
    ['grey_60', colors.grey_60],
    ['grey_50', colors.grey_50],
    ['grey_45', colors.grey_45],
    ['grey_40', colors.grey_40],
    ['grey_30', colors.grey_30],
    ['grey_20', colors.grey_20],
    ['grey_10', colors.grey_10],
    ['grey_5', colors.grey_5],
    ['grey_1', colors.grey_1],
  ];

  const greenPalette = [
    ['green_100', colors.green_100],
    ['green_80', colors.green_80],
    ['green_70', colors.green_70],
    ['green_60', colors.green_60],
    ['green_50', colors.green_50],
    ['green_45', colors.green_45],
    ['green_40', colors.green_40],
    ['green_30', colors.green_30],
    ['green_20', colors.green_20],
    ['green_10', colors.green_10],
    ['green_5', colors.green_5],
    ['green_1', colors.green_1],
  ];

  const redPalette = [
    ['red_100', colors.red_100],
    ['red_80', colors.red_80],
    ['red_70', colors.red_70],
    ['red_60', colors.red_60],
    ['red_50', colors.red_50],
    ['red_45', colors.red_45],
    ['red_40', colors.red_40],
    ['red_30', colors.red_30],
    ['red_20', colors.red_20],
    ['red_10', colors.red_10],
    ['red_5', colors.red_5],
    ['red_1', colors.red_1],
  ];

  const yellowPalette = [
    ['yellow_100', colors.yellow_100],
    ['yellow_90', colors.yellow_90],
    ['yellow_80', colors.yellow_80],
    ['yellow_70', colors.yellow_70],
    ['yellow_60', colors.yellow_60],
    ['yellow_50', colors.yellow_50],
    ['yellow_40', colors.yellow_40],
    ['yellow_30', colors.yellow_30],
    ['yellow_20', colors.yellow_20],
    ['yellow_10', colors.yellow_10],
    ['yellow_5', colors.yellow_5],
    ['yellow_1', colors.yellow_1],
  ];

  return (
    <>
      <CaptionXS as="h1">Colors</CaptionXS>
      <H1 as="h2" mb="6">
        Primary colors
      </H1>
      <ColorPageRow>
        <ColorCard colorStyle={colors.sunnyPeach} name="sunnyPeach" />

        <ColorCard colorStyle={colors.royalBlue} name="royalBlue" />
      </ColorPageRow>

      <H3 mb="4">Palettes</H3>
      <ColorspaceButtons
        activeColorSpace={colorSpace}
        onClick={setColorSpace}
      />
      <ColorPageRow>
        <ColorPalette colors={peachPalette} colorSpace={colorSpace} />
        <ColorPalette colors={bluePalette} colorSpace={colorSpace} />
      </ColorPageRow>

      <H3 mb="2">Color theory</H3>
      <ColorPageRow>
        <BodyM>
          The color <strong>Sunny Peach</strong> is playful and warm and is a
          perfect emotional contrast to the <strong>Royal Blue</strong> that
          communicates a more formal and professional seriousness.
        </BodyM>
      </ColorPageRow>

      <H3 mb="2">Usage</H3>
      <ColorPageRow gridGap={{ s: '4', m: '6' }}>
        <BodyM>
          Use our primary colors on functional elements to draw attention to the
          element. Avoid using peach on large elements since it quickly makes
          the impression of the interface being very orange.
        </BodyM>
        <BodyM>
          Only the blue scale should be used on larger elements such as a footer
          or a large display text. Think about the amounts like the sun and the
          sky - lots of blue sky, while the sun is a smaller part of the sky.
        </BodyM>
      </ColorPageRow>

      <HR />

      <CaptionXS>Colors</CaptionXS>
      <H1 as="h2" mb="6">
        Secondary colors
      </H1>
      <ColorPageRow alignItems="stretch">
        <ColorCard colorStyle={colors.darkLilac} name="darkLilac" />
        <ColorCard colorStyle={colors.silicium} name="silicium" />
        <ColorCard colorStyle={colors.paleLilac} name="paleLilac" />
        <ColorCard colorStyle="#ffffff" name="white" />
      </ColorPageRow>

      <H3 mb="3">Palettes</H3>
      <ColorspaceButtons
        activeColorSpace={colorSpace}
        onClick={setColorSpace}
      />
      <ColorPageRow>
        <ColorPalette colors={lilacPalette} colorSpace={colorSpace} />
        <ColorPalette colors={greyPalette} colorSpace={colorSpace} />
      </ColorPageRow>

      <H3 mb="2">Color theory</H3>
      <ColorPageRow gridGap={{ s: '4', m: '6' }}>
        <div>
          <BodyM mb="4">
            <strong>Dark Lilac</strong> and <strong>Pale Lilac</strong> bring
            out the best in the primary colors. The purple hue is a contrasting
            color to the peachy orange. At the same time, the relative redness
            (purple = red + blue) to the blue scale brings out the ultra marine
            intensity of the Royal Blue.
          </BodyM>
          <BodyM>
            The <strong>Pale Lilac</strong> provides a bit of warmth to the
            composition and helps bring out the deeper notes in the other
            colors. This gives the composition more depth and life.
          </BodyM>
        </div>
        <div>
          <BodyM mb="4">
            The brand grey color is drawn from <strong>Silicium</strong>, one of
            the core colors in the physical world of our business.
          </BodyM>
          <BodyM>
            <strong>White</strong> is chosen to give the feeling of clean, airy
            space and being precise.
          </BodyM>
        </div>
      </ColorPageRow>

      <Box
        display="grid"
        gridTemplateColumns={{
          s: '1fr',
          s2: 'repeat(2, 1fr)',
          m3: 'repeat(4, 1fr)',
        }}
        gridGap={{ s: '6', s3: '6', m3: '6' }}
        mb="6">
        <Box width={{ s: '100%', m: '80%', m3: '100%' }}>
          <Img src={lilac1} alt="" />
          <BodyS color="text_muted">
            <strong>Sunny Peach</strong> with blend mode "multiply" on top of{' '}
            <strong>Royal Blue</strong>. The color at the intersection appears
            dull, and makes the primary colors look duller as well.
          </BodyS>
        </Box>
        <Box width={{ s: '100%', m: '80%', m3: '100%' }}>
          <Img src={lilac2} alt="" />
          <BodyS color="text_muted">
            <strong>Dark Lilac</strong> at the intersection of Sunny Peach and
            Royal Blue make the two primary colors appear clearer and fuller,
            while the Dark Lilac gets its own personality.
          </BodyS>
        </Box>
        <Box width={{ s: '100%', m: '80%', m3: '100%' }}>
          <Img src={lilac3} alt="" />
          <BodyS color="text_muted">
            Sunny Peach and Royal Blue complemented with Dark Lilac and{' '}
            <strong>Blue-2</strong>. Blue-2 gives the composition a fresh, sharp
            feeling, but a little cold, steel-like and flat.
          </BodyS>
        </Box>
        <Box width={{ s: '100%', m: '80%', m3: '100%' }}>
          <Img src={lilac4} alt="" />
          <BodyS color="text_muted">
            Sunny Peach and Royal Blue complemented with{' '}
            <strong>Dark Lilac</strong> and <strong>Pale Lilac</strong>. The
            Pale lilac provides the composition with a little warmth that brings
            out the deeper notes in all the other colors. This gives the
            composition more depth and life.
          </BodyS>
        </Box>
      </Box>

      <H3 mb="2">Usage</H3>
      <ColorPageRow gridGap={{ s: '4', m: '6' }}>
        <div>
          <BodyM mb="4">
            If you use the Sunny Peach as a sun and the Royal Blue as a
            background, the <strong>Dark Lilac</strong> creates a clear, proud
            shadow between the two colors that makes them both stand out; see
            the following color test of Dark Shadow.
          </BodyM>
          <BodyM>
            The <strong>Pale Lilac</strong> is used on small elements that could
            have been grey, but where a little color brings that extra to the
            composition. Example: The horisontal rules on this page and selected
            indicator in the main navigation.
          </BodyM>
        </div>
        <div>
          <BodyM mb="4">
            <strong>Silicium</strong> is used on most body text and paragraphs.
            Faded text (used on captions) uses the color <Code>grey_45</Code>.
          </BodyM>
          <BodyM>
            <strong>White</strong> should mainly be used as backgrounds.
          </BodyM>
        </div>
      </ColorPageRow>

      <HR />

      <CaptionXS>Colors</CaptionXS>
      <H1 as="h2" mb="6">
        Functional colors
      </H1>
      <ColorPageRow alignItems="stretch">
        <ColorCard colorStyle={colors.digital} name="digital" />
        <ColorCard colorStyle={colors.digitalite} name="digitalite" />
        <ColorCard colorStyle={colors.eGreen} name="eGreen" />
        <ColorCard colorStyle={colors.scarlet} name="scarlet" />
      </ColorPageRow>

      <H3 mb="4">Palettes</H3>
      <ColorspaceButtons
        activeColorSpace={colorSpace}
        onClick={setColorSpace}
      />
      <ColorPageRow>
        <ColorPalette colors={greenPalette} colorSpace={colorSpace} />
        <ColorPalette colors={redPalette} colorSpace={colorSpace} />
      </ColorPageRow>

      <ColorPageRow>
        <ColorPalette colors={yellowPalette} colorSpace={colorSpace} />
      </ColorPageRow>

      <H3 mb="2">Color theory</H3>
      <ColorPageRow gridGap={{ s: '4', m: '6' }}>
        <BodyM>
          The <strong>Egreen</strong> and <strong>Scarlet</strong> are both
          chosen to harmonise next to our primary colors at the same time as
          they feel quite technological and playful. This is to take easy any
          stress our users might have when it comes to using technical
          interfaces.
        </BodyM>
        <BodyM>
          <strong>Digital</strong> and <strong>Digitalite</strong> are chosen to
          draw attention and look clickable next to our Royal Blue.
        </BodyM>
      </ColorPageRow>

      <H3 mb="2">Usage</H3>
      <ColorPageRow gridGap={{ s: '4', m: '6' }}>
        <BodyM>
          Our <strong>Green</strong> scale is used on feedback messages or
          elements that meand success, ready, done, etc. Our{' '}
          <strong>Red</strong> scale is used on feedback messages or elements
          that meand failed, danger, not done, etc.
        </BodyM>
        <BodyM>
          <strong>Digital</strong> is used as link color on White and light
          backgrounds. <strong>Digitalite</strong> is used as link color on dark
          backgrounds.
        </BodyM>
      </ColorPageRow>

      <HR />

      <CaptionXS as="h1">Colors</CaptionXS>
      <H1 as="h2" mb="6">
        Gradients
      </H1>
      <ColorPageRow>
        <GradientCard
          backgroundImage={gradients.gradientBlue}
          name="gradientBlue"
          direction="Horizontal"
          start="blue_50"
          end="blue_60"
        />
        <GradientCard
          backgroundImage={gradients.gradientPeach}
          name="gradientPeach"
          direction="Horizontal"
          start="peach_50"
          end="peach_60"
        />
        <GradientCard
          backgroundImage={gradients.gradientBlueLight}
          name="gradientBlueLight"
          direction="Horizontal"
          start="blue_5"
          end="blue_10"
          darkLabel
        />
        <GradientCard
          backgroundImage={gradients.gradientPeachLight}
          name="gradientPeachLight"
          direction="Horizontal"
          start="peach_1"
          end="peach_5"
          darkLabel
        />
        <GradientCard
          backgroundImage={gradients.gradientLilac}
          name="gradientLilac"
          direction="Vertical"
          start="lilac_1"
          end="lilac_5"
          darkLabel
        />
        <GradientCard
          backgroundImage={gradients.gradientSun}
          name="gradientSun"
          direction="45°"
          start="#EA5634"
          end="#FBC739"
          darkLabel
        />
      </ColorPageRow>

      <H3 mb="2">Usage</H3>
      <ColorPageRow gridGap={{ s: '4', m: '6' }}>
        <BodyM>
          <strong>G Blue</strong> is our primary blue gradient, used on primary
          buttons. <strong>G Peach</strong> is our primary peach gradient, used
          on primary buttons.
        </BodyM>
        <BodyM>
          <strong>G Blue Light</strong> is our secondary blue gradient, used on
          secondary buttons. <strong>G Lilac</strong> is our most commonly used
          background color, where we need a background in order to highlight a
          card laying on top.
        </BodyM>
      </ColorPageRow>
    </>
  );
};

export default withTheme(ColorsPage);
