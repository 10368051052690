import React, { Component } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Router, View, NotFoundBoundary } from 'react-navi';
import HelmetProvider from 'react-navi-helmet-async';
import { theme } from '@otovo/rainbow';

import routes from './routes';

import Layout from './apps/Layout/Layout';
import NotFoundPage from './apps/NotFoundPage/NotFoundPage';

// TODO: Remove when Rainbow starts defining these
const customTheme = {
  ...theme,
  shadows: ['-3px 8px 20px 0 #1d2b5d23'],
};

customTheme.fonts.monospace =
  '"Source Code Pro", Consolas, Menlo, Monaco, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, monospace';

class App extends Component {
  render() {
    return (
      <HelmetProvider>
        <Router routes={routes}>
          <ThemeProvider theme={customTheme}>
            <Layout>
              <NotFoundBoundary render={() => <NotFoundPage />}>
                <View />
              </NotFoundBoundary>
            </Layout>
          </ThemeProvider>
        </Router>
      </HelmetProvider>
    );
  }
}

export default App;
