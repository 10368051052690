import { HEX, HSL, RGB, CMYK } from '../constants';

import hexToRgb from './hexToRgb';
import hexToCmyk from './hexToCmyk';
import hexToHsl from './hexToHsl';

// Takes a hex string or an array of hex strings, and turns it into
// another colorSpace such as CMYK or HSL.
export default function hexToAnything(colors, colorSpace) {
  const toColorArgumentType = arr => (arr.length > 1 ? arr : arr[0]);

  const colorArr = Array.isArray(colors) ? colors : [colors];

  if (colorSpace === HEX) {
    return toColorArgumentType(colorArr.map(c => c.toUpperCase()));
  }
  if (colorSpace === HSL) {
    return toColorArgumentType(colorArr.map(c => hexToHsl(c)));
  }
  if (colorSpace === RGB) {
    return toColorArgumentType(colorArr.map(c => hexToRgb(c)));
  }
  if (colorSpace === CMYK) {
    return toColorArgumentType(colorArr.map(c => hexToCmyk(c)));
  }

  return colors;
}
