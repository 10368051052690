import React from 'react';
import { Link } from '@otovo/rainbow';

export const source = `
import { Link } from '@otovo/rainbow';

<Link href="#">Read more</Link>
`;

export const render = (
  <Link href="https://www.youtube.com/watch?v=OQSNhk5ICTI">Read more</Link>
);
