import React from 'react';

import { Toggle } from '@otovo/rainbow';

export const source = `
import { Input } from '@otovo/rainbow';

<Toggle id="exampleBasic" />
`;

export const render = <Toggle id="exampleBasic" />;
