import React from 'react';

import Table from '../../../components/Table';

const TableSizeComparison = () => {
  return (
    <Table firstColumn="bold">
      <thead>
        <tr>
          <th>Index</th>
          <th>Rainbow</th>
          <th>Tachyons (space)</th>
          <th>Tachyons (size)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0</td>
          <td>0</td>
          <td>0</td>
          <td>0</td>
        </tr>
        <tr>
          <td>1</td>
          <td>4</td>
          <td>4</td>
          <td>16</td>
        </tr>
        <tr>
          <td>2</td>
          <td>8</td>
          <td>8</td>
          <td>32</td>
        </tr>
        <tr>
          <td>3</td>
          <td>12</td>
          <td>16</td>
          <td>64</td>
        </tr>
        <tr>
          <td>4</td>
          <td>16</td>
          <td>32</td>
          <td>128</td>
        </tr>
        <tr>
          <td>5</td>
          <td>24</td>
          <td>64</td>
          <td>256</td>
        </tr>
        <tr>
          <td>6</td>
          <td>32</td>
          <td>128</td>
          <td>512</td>
        </tr>
        <tr>
          <td>7</td>
          <td>48</td>
          <td>256</td>
          <td>768</td>
        </tr>
        <tr>
          <td>8</td>
          <td>64</td>
          <td></td>
          <td>1024</td>
        </tr>
        <tr>
          <td>9</td>
          <td>96</td>
          <td></td>
          <td>1536</td>
        </tr>
        <tr>
          <td>10</td>
          <td>128</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>11</td>
          <td>192</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>12</td>
          <td>256</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>13</td>
          <td>384</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>14</td>
          <td>512</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>15</td>
          <td>640</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>16</td>
          <td>768</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>17</td>
          <td>1024</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>18</td>
          <td>1280</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>19</td>
          <td>1536</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableSizeComparison;
