import React from 'react';
import { Link } from '@otovo/rainbow';

export const source = `
<Link href="#" icon={IconChevronRight}>
  Next
</Link>
`;

const IconChevronRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round">
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

export const render = (
  <Link
    href="https://www.youtube.com/watch?v=-gSYKgAMPBc"
    icon={IconChevronRight}>
    Next
  </Link>
);
