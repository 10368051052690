import React from 'react';
import { H1, BodyM, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';
import HR from '../../components/HR';
import Example from '../../components/Example/Example';
import Code from '../../components/Code';

import * as exampleBasic from './examples/exampleBasic';
import * as exampleSize from './examples/exampleSize';
import * as exampleOverrides from './examples/exampleOverrides';

const GradientTextPage = () => (
  <Box>
    <CaptionXS as="h1">Badge</CaptionXS>
    <H1 as="h2" mb="5">
      Basic usage
    </H1>
    <Example {...exampleBasic} />
    <HR />

    <CaptionXS>Badge</CaptionXS>
    <H1 as="h2" mb="3">
      Size
    </H1>
    <BodyM mb="6" maxWidth="measure">
      By default, <Code>Badge</Code> will adapt to the font size of its parent.
      However, this can be overridden if needed.
    </BodyM>
    <Example {...exampleSize} />
    <HR />

    <CaptionXS>Badge</CaptionXS>
    <H1 as="h2" mb="3">
      Custom overrides
    </H1>
    <BodyM mb="6" maxWidth="measure">
      If needed, you can change stuff like it's gradient.
    </BodyM>
    <Example {...exampleOverrides} />
  </Box>
);

export default GradientTextPage;
