import React from 'react';
import {
  H0,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  BodyL,
  BodyM,
  BodyS,
  BodyXS,
  CaptionM,
  CaptionS,
  CaptionXS,
} from '@otovo/rainbow';
import { withTheme } from 'emotion-theming';

import Box from '../../components/Box';

const supportedTags = {
  H0: H0,
  H1: H1,
  H2: H2,
  H3: H3,
  H4: H4,
  H5: H5,
  H6: H6,
  'Body L': BodyL,
  'Body M': BodyM,
  'Body S': BodyS,
  'Body XS': BodyXS,
  'Caption M': CaptionM,
  'Caption S': CaptionS,
  'Caption XS': CaptionXS,
};

const TextDefinition = ({
  tag,
  weight,
  size,
  tracking,
  lineHeight,
  children,
}) => {
  const TagName = supportedTags[tag];

  return (
    <Box display="flex" flexWrap={{ s: 'wrap', m: 'nowrap' }} mb="6">
      <Box flex={['0 0 100%', '0 0 256px']} mr="4">
        <TagName mb="1">{tag}</TagName>
        <Box display="flex">
          <Box width={1 / 3}>
            <BodyXS>Weight</BodyXS>
            <BodyXS>Size</BodyXS>
            <BodyXS>Line height</BodyXS>
            <BodyXS>Tracking</BodyXS>
          </Box>
          <Box width={2 / 3}>
            <BodyXS>{weight}</BodyXS>
            <BodyXS>{size}</BodyXS>
            <BodyXS>{lineHeight}</BodyXS>
            <BodyXS>{tracking}</BodyXS>
          </Box>
        </Box>
      </Box>
      <Box width={['100%', 'initial']} my={{ s: '4', m: '0' }}>
        <TagName as="p">{children}</TagName>
      </Box>
    </Box>
  );
};

export default withTheme(TextDefinition);
