import React from 'react';
import { Text } from '@otovo/rainbow';

export const source = `
import { Text } from '@otovo/rainbow';

<Text fontSize="8">So big!</Text>
<Text fontSize="_5a">Remember the underscore when using extra values such as "_5a"</Text>
`;

export const render = (
  <>
    <Text fontSize="8" mb="3">
      So big!
    </Text>
    <Text fontSize="_5a">
      Remember the underscore when using extra values such as "_5a"
    </Text>
  </>
);
