import React from 'react';
import styled from '@emotion/styled';

import { get, BodyM, CaptionXS } from '@otovo/rainbow';

import Box from '../../components/Box';

const Swatch = ({ backgroundImage }) => (
  <Box
    gridArea="swatch"
    backgroundImage={backgroundImage}
    position="relative"
    width="100%"
    minHeight="10"
  />
);

const CardWrap = styled(Box)`
  background: white;
  box-shadow: ${get('shadows.0')};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'swatch    swatch'
    'name      direction'
    'start     end';
`;

const GradientCard = ({
  width,
  backgroundImage,
  name,
  direction,
  start,
  end,
}) => {
  return (
    <CardWrap width={width}>
      <Swatch backgroundImage={backgroundImage} />
      <Box gridArea="name" p="5" pb="0">
        <CaptionXS>Name</CaptionXS>
        <BodyM>{name}</BodyM>
      </Box>
      <Box gridArea="direction" p="5">
        <CaptionXS>Direction</CaptionXS>
        <BodyM>{direction}</BodyM>
      </Box>
      <Box gridArea="start" p="5" pt="0">
        <CaptionXS>Start</CaptionXS>
        <BodyM>{start}</BodyM>
      </Box>
      <Box gridArea="end" p="5" pt="0">
        <CaptionXS>End</CaptionXS>
        <BodyM>{end}</BodyM>
      </Box>
    </CardWrap>
  );
};

export default GradientCard;
