import React from 'react';
import Box from '../../../components/Box';

export const source = `
import { Box } from '@otovo/rainbow';

<Box
  bg={{
    s: 'green_50',
    m2: 'blue_50',
    l: 'red_50'
  }}
  height="10"
  width="10"
/>
`;

export const render = (
  <Box
    bg={{ s: 'green_50', m2: 'blue_50', l: 'red_50' }}
    height="10"
    width="10"
  />
);
