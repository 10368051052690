export const source = `
import { SyntaxHighlighter } from 'react-syntax-highlighter';
import { withTheme } from 'emotion-theming';

const SyntaxHighlighterWrapper = ({ theme, children }) => (
  <SyntaxHighlighter
    customStyle={{
      padding: theme.space[5],
    }}>
    {children}
  </SyntaxHighlighter>
);

export default withTheme(SyntaxHighlighterWrapper);
`;
