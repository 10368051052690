import React from 'react';

import { Box, Label, LabelDescription } from '@otovo/rainbow';

export const source = `
<Box p="5" bg="darkLilac">
  <Label contrastTo="darkBackground">Password</Label>
  <LabelDescription contrastTo="darkBackground">Must have at least 6 characters</LabelDescription>
</Box>

<Box p="5" bg="lilac_5">
  <Label>Password</Label>
  <LabelDescription>Must have at least 6 characters</LabelDescription>
</Box>
`;

export const render = (
  <>
    <Box p="5" bg="darkLilac">
      <Label contrastTo="darkBackground">Password</Label>
      <LabelDescription contrastTo="darkBackground">
        Must have at least 6 characters
      </LabelDescription>
    </Box>
    <Box p="5" bg="lilac_5">
      <Label>Password</Label>
      <LabelDescription>Must have at least 6 characters</LabelDescription>
    </Box>
  </>
);
