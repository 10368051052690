import React from 'react';

import { Box, Toggle } from '@otovo/rainbow';

export const source = `
<Box width="50%" p="5" bg="darkLilac">
  <Toggle contrastTo="darkBackground" id="exampleContrastTo1" />
</Box>

<Box width="50%" p="5" bg="lilac_5">
  <Toggle contrastTo="lightBackground" id="exampleContrastTo2" />
</Box>
`;

export const render = (
  <>
    <Box display="flex" width="100%">
      <Box width="50%" p="5" bg="darkLilac">
        <Toggle contrastTo="darkBackground" id="exampleContrastTo1" />
      </Box>
      <Box width="50%" p="5" bg="lilac_5">
        <Toggle contrastTo="lightBackground" id="exampleContrastTo2" />
      </Box>
    </Box>
  </>
);
